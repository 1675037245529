import React, { useEffect, useState } from "react";
import "./categorias.css";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import ModalFiltro from "./modalFiltro";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    background: "#faac2a",
  },
}));

export default function PrimerFiltro(props) {
  const classes = useStyles();
  const [chipData, setChipData] = React.useState([
    { key: 0, label: props.selectedFilter },
  ]);

  const [filtroDos, setFiltroDos] = useState(false);

  const [rendered, setrendered] = useState(false);
  const [filtroTipo, setFiltroTipo] = useState([]);
  const [filtroMarca, setFiltroMarca] = useState([]);

  const handleDelete = (chipToDelete) => () => {
    if (chipToDelete.key === 0) props.getPublicFilter();
    if (chipToDelete.key === 1) setrendered(false);
    if (chipToDelete.key === 2) setrendered(false);
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    if (chipToDelete.key === 0) props.handleFiltro();
  };

  const handleFiltroDos = (e) => {
    if (chipData.length < 2) {
      setChipData([...chipData, { key: 1, label: e.currentTarget.textContent }]);

      let url = "https://idecommerce.cl/services/api/get/filters/by/types";
      axios
        .post(
          url,
          {
           // categoria: props.categoria,
            filterid:  e.currentTarget.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          
          console.log(response);
          let result   = (!response.data) ? props.setPublicaciones([]) : props.setPublicaciones(response.data);
          console.log(result);
        });
    }
  };

  const handleFiltroTres = (e) => {
    if (chipData.length < 3) {
      setChipData([...chipData, { key: 2, label: e.currentTarget.textContent }]);
      let url = "https://idecommerce.cl/services/api/get/filters/by/brands";
      axios
        .post(
          url,
          {
           // categoria: props.categoria,
            filterid:  e.currentTarget.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          
          console.log(response);
          let result   = (!response.data) ? props.setPublicaciones([]) : props.setPublicaciones(response.data);
          console.log(result);
        });
    }
  };

  useEffect(() => {
    if (!rendered) {
      
      let url = "https://idecommerce.cl/services/api/get/pubs-filter";
      axios
        .post(
          url,
          {
            categoria: props.categoria,
            filterid: props.selectedId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          
          props.setPublicaciones(response.data.publicaciones);
          setFiltroTipo(response.data.filtrotype);
          setFiltroMarca(response.data.filtromarca);
        
        });
      setrendered(true);
    }
  }, [props, rendered]);

  return (
    <section>
      <Paper component="ul" className={classes.root}>
        {chipData.map((data) => {
          return (
            <li key={data.key}>
              <Chip
                size="small"
                label={data.label}
                onDelete={handleDelete(data)}
                className={classes.chip}
              />
            </li>
          );
        })}
      </Paper>

      <div>
        {filtroTipo &&
          filtroTipo.slice(0, 5).map((tipo) => {
            return (
              <Chip
                size="small"
                label={tipo.name}
                value={tipo.name}
                id={tipo.id}
                className="chip-cat-uno"
                onClick={handleFiltroDos}
              />
            );
          })}

        <hr />
        <ModalFiltro filtroTipo={filtroTipo} handlerFiltro={handleFiltroDos} />
      </div>

      <div className='mt-5'>
        {filtroMarca &&
          filtroMarca.slice(0, 5).map((tipo) => {
            return (
              <Chip
                size="small"
                label={tipo.name}
                value={tipo.name}
                id={tipo.id}
                className="chip-cat-uno"
                onClick={handleFiltroTres}
              />
            );
          })}
        <hr />
        <ModalFiltro filtroMarca={filtroMarca} handlerFiltro={handleFiltroTres} />
      </div>
    </section>
  );
}
