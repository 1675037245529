import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import "./publicacion.css";
import { PhotoCamera } from "@material-ui/icons";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import EditIcon from "@material-ui/icons/Edit";
import ModalCustom from "../Modal/modal";

/**
 *
 * @var {titulo} Titulo de la publicacion
 * @var {tipo} Tipo de publicacion [Auto,Moto]
 * @var {info} Informacion de la publicacion
 * @returns
 *
 */

export default function PublicacionEdit(props) {
  let history = useHistory();

  const [cookies, setCookie, removeCookie] = useCookies(["email"]);
  const [publicacion, setPublicacion] = useState(null);
  const [rendered, setRenedered] = useState(false);

  const [imgpre, setimgpre] = useState(null);
  const [file_data, setFiledata] = useState(null);

  const [imgsec, setimgsec] = useState(null);
  const [file_data_second, setFiledatasecond] = useState(null);

  const [imgthr, setimgthr] = useState(null);
  const [file_data_three, setFiledatathree] = useState(null);

  const [imgfour, setimgfour] = useState(null);
  const [file_data_four, setFiledatafour] = useState(null);

  const [imgfive, setimgfive] = useState(null);
  const [file_data_five, setFiledatafive] = useState(null);

  const [imgsix, setimgsix] = useState(null);
  const [file_data_six, setFiledatasix] = useState(null);

  const [cara, setCara] = useState(null);
  const [caraid, setCaraId] = useState(null);
  const [val, setVal] = useState(null);

  const [cara_dos, setCarados] = useState(null);
  const [caraid_dos, setCaraIddos] = useState(null);
  const [val_dos, setValdos] = useState(null);

  const [cara_tres, setCaratres] = useState(null);
  const [val_tres, setValtres] = useState(null);
  const [caraid_tres, setCaraIdtres] = useState(null);

  const [cara_cuatro, setCaracuatro] = useState(null);
  const [val_cuatro, setValcuatro] = useState(null);
  const [caraid_cuatro, setCaraIdcuatro] = useState(null);

  const [cara_cinco, setCaracinco] = useState(null);
  const [val_cinco, setValcinco] = useState(null);
  const [caraid_cinco, setCaraIdcinco] = useState(null);

  const [cara_seis, setCaraseis] = useState(null);
  const [val_seis, setValseis] = useState(null);
  const [caraid_seis, setCaraIdseis] = useState(null);

  const preloadImg = (e) => {
    setimgpre(URL.createObjectURL(e.target.files[0]));
    setFiledata(e.target.files[0]);
  };

  const preloadImgsec = (e) => {
    setimgsec(URL.createObjectURL(e.target.files[0]));
    setFiledatasecond(e.target.files[0]);
  };

  const preloadImgthr = (e) => {
    setimgthr(URL.createObjectURL(e.target.files[0]));
    setFiledatathree(e.target.files[0]);
  };

  const preloadImgfour = (e) => {
    setimgfour(URL.createObjectURL(e.target.files[0]));
    setFiledatafour(e.target.files[0]);
  };

  const preloadImgfive = (e) => {
    setimgfive(URL.createObjectURL(e.target.files[0]));
    setFiledatafive(e.target.files[0]);
  };

  const preloadImgsix = (e) => {
    setimgsix(URL.createObjectURL(e.target.files[0]));
    setFiledatasix(e.target.files[0]);
  };

  const uploadImgs = (idpublic, file) => {
    let endpoint = "https://idecommerce.cl/services/api/create/publicacion/multimedia";
    var bodyImgs = new FormData();
    bodyImgs.append("file", file);
    bodyImgs.append("idPublic", idpublic);

    const config = {
      headers: {
        Authorization: "Bearer " + cookies.auth_user,
      },
    };
    axios.post(endpoint, bodyImgs, config);
  };

  const submitUpdate = async () => {
    let endpoint = "https://idecommerce.cl/services/api/update/publicacion";

    let lscaras = [];
    if (cara && val) lscaras.push({ id: caraid, name: cara, valor: val });
    if (cara_dos && val_dos)
      lscaras.push({ id: caraid_dos, name: cara_dos, valor: val_dos });
    if (cara_tres && val_tres)
      lscaras.push({ id: caraid_tres, name: cara_tres, valor: val_tres });
    if (cara_cuatro && val_cuatro)
      lscaras.push({ id: caraid_cuatro, name: cara_cuatro, valor: val_cuatro });
    if (cara_cinco && val_cinco)
      lscaras.push({ id: caraid_cinco, name: cara_cinco, valor: val_cinco });
    if (cara_seis && val_seis)
      lscaras.push({ id: caraid_seis, name: cara_seis, valor: val_seis });

    const params = {
      idPubli: publicacion.id,
      Titulo: publicacion.Titulo,
      Informacion: publicacion.Informacion,
      Precio: publicacion.Precio,
      Tipo: publicacion.Tipo,
      Caraceristicas: lscaras,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.auth_user,
      },
    };
    await axios.post(endpoint, params, config).then((res) => {
      alert(res.data.status);
      if (res.data.status === "Fue actualizada") {
        history.push("/Publicacion/" + publicacion.id);
      }
    });
  };

  useEffect(() => {
    if(!cookies.auth_user){
      history.push('/login');
    }   

    if (!rendered) {
      let url = "https://idecommerce.cl/services/api/get/publicacionuser/edit";
      axios
        .post(
          url,
          {
            idPubli: props.publicacion.id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.auth_user,
            },
          }
        )
        .then((response) => {
          setPublicacion(response.data);
          for (
            let index = 0;
            index < response.data.multimedias.length;
            index++
          ) {
            switch (index) {
              case 0:
                setimgpre(
                  "https://idecommerce.cl/services/api/storage/" +
                    response.data.multimedias[index].url
                );

                break;
              case 1:
                setimgsec(
                  "https://idecommerce.cl/services/api/storage/" +
                    response.data.multimedias[index].url
                );

                break;
              case 2:
                setimgthr(
                  "https://idecommerce.cl/services/api/storage/" +
                    response.data.multimedias[index].url
                );

                break;
              case 3:
                setimgfour(
                  "https://idecommerce.cl/services/api/storage/" +
                    response.data.multimedias[index].url
                );

                break;
              case 4:
                setimgfive(
                  "https://idecommerce.cl/services/api/storage/" +
                    response.data.multimedias[index].url
                );

                break;
              case 5:
                setimgsix(
                  "https://idecommerce.cl/services/api/storage/" +
                    response.data.multimedias[index].url
                );

                break;
              default:
                break;
            }
          }
          for (
            let index = 0;
            index < response.data.caracteristicas.length;
            index++
          ) {
            switch (index) {
              case 0:
                setCaraId(response.data.caracteristicas[index].id);
                setCara(response.data.caracteristicas[index].name);
                setVal(response.data.caracteristicas[index].valor);
                break;
              case 1:
                setCaraIddos(response.data.caracteristicas[index].id);
                setCarados(response.data.caracteristicas[index].name);
                setValdos(response.data.caracteristicas[index].valor);

                break;
              case 2:
                setCaraIdtres(response.data.caracteristicas[index].id);

                setCaratres(response.data.caracteristicas[index].name);
                setValtres(response.data.caracteristicas[index].valor);

                break;
              case 3:
                setCaraIdcuatro(response.data.caracteristicas[index].id);

                setCaracuatro(response.data.caracteristicas[index].name);
                setValcuatro(response.data.caracteristicas[index].valor);

                break;

              case 4:
                setCaraIdcinco(response.data.caracteristicas[index].id);

                setCaracinco(response.data.caracteristicas[index].name);
                setValcinco(response.data.caracteristicas[index].valor);

                break;
              case 5:
                setCaraIdseis(response.data.caracteristicas[index].id);
                setCaraseis(response.data.caracteristicas[index].name);
                setValseis(response.data.caracteristicas[index].valor);
                break;
              default:
                break;
            }
          }
        });
      setRenedered(true);
    }
  }, [publicacion]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  };

  /**
   *  Seccion para hacer upload  de las img
   */
  const [btnEditImg, setbtnEditImg] = useState("d-none");
  const [btnEditImgSec, setbtnEditImgSec] = useState("d-none");
  const [btnEditImgThr, setbtnEditImgThr] = useState("d-none");
  const [btnEditImgFour, setbtnEditImgFour] = useState("d-none");
  const [btnEditImgFive, setbtnEditImgFive] = useState("d-none");
  const [btnEditImgSix, setbtnEditImgSix] = useState("d-none");

  const showButton = (e) => {
    setbtnEditImg("d-block");
  };

  const showButtonSecond = (e) => {
    setbtnEditImgSec("d-block");
  };

  const showButtonThr = (e) => {
    setbtnEditImgThr("d-block");
  };

  const showButtonFour = (e) => {
    setbtnEditImgFour("d-block");
  };

  const showButtonFive = (e) => {
    setbtnEditImgFive("d-block");
  };

  const showButtonSix = (e) => {
    setbtnEditImgSix("d-block");
  };

  const [modalShow, setModalShow] = React.useState(false);
  const [currentImg, setCurrentImg] = useState(null);
  const deleteImg = (imgpre, e) => {
    setCurrentImg(imgpre);
    childRef.current.toggleDrawerChild('left', true)
    setModalShow(true);
  };

  const childRef = useRef();

  return (
    <section className="section-box pb-5 pt-0 mt-3 position-relative ">
     <ModalCustom 
                    show={modalShow} 
                    onHide={() => setModalShow(false)}
                    currentImg ={currentImg}
                    ref={childRef} 
                    />

      {publicacion && (
        <form noValidate autoComplete="off" className="">
          <section className=" sec-pub">
            <Paper
              variant="outlined"
              className="box-img d-flex align-items-center justify-content-center"
              square
            >
              {imgpre && (
                <>
                 
                  <button
                    className={
                      "btn  btn-warning text-white btn-edit position-absolute  " +
                      btnEditImg
                    }
                    onMouseOver={showButton}
                    onMouseLeave={() => {
                      setbtnEditImg("d-none");
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      deleteImg(imgpre, e);
                    }}
                  >
                    <EditIcon />
                  </button>
                  <img
                    src={imgpre}
                    className={"w-100 h-auto "}
                    style={{ maxHeight: "100%" }}
                    onMouseOver={showButton}
                    onMouseLeave={() => {
                      setbtnEditImg("d-none");
                    }}
                  />
                </>
              )}
              {!imgpre && (
                <>
                  <input
                    accept="image/*"
                    className={"d-none"}
                    id="icon-button-file"
                    type="file"
                    name="imgpre"
                    onChange={preloadImg}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <PhotoCamera />
                    </IconButton>
                  </label>
                </>
              )}
            </Paper>
            <section className="section-box-info ps-3    text-start">
              <section className="d-flex">
                <Paper
                  variant="outlined"
                  className="box-subimg d-flex align-items-center justify-content-center"
                  square
                >
                  {imgsec && (
                    <>
                      <button
                        className={
                          "btn  btn-warning text-white btn-edit position-absolute  " +
                          btnEditImgSec
                        }
                        onMouseOver={showButtonSecond}
                        onMouseLeave={() => {
                          setbtnEditImgSec("d-none");
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteImg(imgsec, e);
                        }}
                      >
                        <EditIcon />
                      </button>
                      <img
                        src={imgsec}
                        className={"w-100 h-auto "}
                        style={{ maxHeight: "100%" }}
                        onMouseOver={showButtonSecond}
                        onMouseLeave={() => {
                          setbtnEditImgSec("d-none");
                        }}
                      />
                    </>
                  )}
                  {!imgsec && (
                    <>
                      <input
                        accept="image/*"
                        className={"d-none"}
                        id="icon-button-file"
                        type="file"
                        name="imgsec"
                        onChange={preloadImgsec}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <PhotoCamera />
                        </IconButton>
                      </label>
                    </>
                  )}
                </Paper>

                <Paper
                  variant="outlined"
                  className="box-subimg d-flex align-items-center justify-content-center"
                  square
                >
                  {imgthr && (
                    <>
                      <button
                        className={
                          "btn  btn-warning text-white btn-edit position-absolute  " +
                          btnEditImgThr
                        }
                        onMouseOver={showButtonThr}
                        onMouseLeave={() => {
                          setbtnEditImgThr("d-none");
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteImg(imgthr, e);
                        }}
                      >
                        <EditIcon />
                      </button>
                      <img
                        src={imgthr}
                        className={"w-100 h-auto "}
                        style={{ maxHeight: "100%" }}
                        onMouseOver={showButtonThr}
                        onMouseLeave={() => {
                          setbtnEditImgThr("d-none");
                        }}
                      />
                    </>
                  )}
                  {!imgthr && (
                    <>
                      <input
                        accept="image/*"
                        className={"d-none"}
                        id="icon-button-file"
                        type="file"
                        name="imgthr"
                        onChange={preloadImgthr}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <PhotoCamera />
                        </IconButton>
                      </label>
                    </>
                  )}
                </Paper>

                <Paper
                  variant="outlined"
                  className="box-subimg d-flex align-items-center justify-content-center"
                  square
                >
                  {imgfour && (
                    <>
                      <button
                        className={
                          "btn  btn-warning text-white btn-edit position-absolute  " +
                          btnEditImgFour
                        }
                        onMouseOver={showButtonFour}
                        onMouseLeave={() => {
                          setbtnEditImgFour("d-none");
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteImg(imgfour, e);
                        }}
                      >
                        <EditIcon />
                      </button>
                      <img
                        src={imgfour}
                        className={"w-100 h-auto "}
                        style={{ maxHeight: "100%" }}
                        onMouseOver={showButtonFour}
                        onMouseLeave={() => {
                          setbtnEditImgFour("d-none");
                        }}
                      />
                    </>
                  )}
                  {!imgfour && (
                    <>
                      <input
                        accept="image/*"
                        className={"d-none"}
                        id="icon-button-file"
                        type="file"
                        onChange={preloadImgfour}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <PhotoCamera />
                        </IconButton>
                      </label>
                    </>
                  )}
                </Paper>

                <Paper
                  variant="outlined"
                  className="box-subimg d-flex align-items-center justify-content-center"
                  square
                >
                  {imgfive && (
                    <>
                      <button
                        className={
                          "btn  btn-warning text-white btn-edit position-absolute  " +
                          btnEditImgFive
                        }
                        onMouseOver={showButtonFive}
                        onMouseLeave={() => {
                          setbtnEditImgFive("d-none");
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteImg(imgfive, e);
                        }}
                      >
                        <EditIcon />
                      </button>
                      <img
                        src={imgfive}
                        className={"w-100 h-auto "}
                        style={{ maxHeight: "100%" }}
                        onMouseOver={showButtonFive}
                        onMouseLeave={() => {
                          setbtnEditImgFive("d-none");
                        }}
                      />
                    </>
                  )}
                  {!imgfive && (
                    <>
                      <input
                        accept="image/*"
                        className={"d-none"}
                        id="icon-button-file"
                        type="file"
                        onChange={preloadImgfive}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <PhotoCamera />
                        </IconButton>
                      </label>
                    </>
                  )}
                </Paper>

                <Paper
                  variant="outlined"
                  className="box-subimg d-flex align-items-center justify-content-center"
                  square
                >
                  {imgsix && (
                    <>
                      <button
                        className={
                          "btn  btn-warning text-white btn-edit position-absolute  " +
                          btnEditImgSix
                        }
                        onMouseOver={showButtonSix}
                        onMouseLeave={() => {
                          setbtnEditImgSix("d-none");
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          deleteImg(imgsix, e);
                        }}
                      >
                        <EditIcon />
                      </button>
                      <img
                        src={imgsix}
                        className={"w-100 h-auto "}
                        style={{ maxHeight: "100%" }}
                        onMouseOver={showButtonSix}
                        onMouseLeave={() => {
                          setbtnEditImgSix("d-none");
                        }}
                      />
                    </>
                  )}
                  {!imgsix && (
                    <>
                      <input
                        accept="image/*"
                        className={"d-none"}
                        id="icon-button-file"
                        type="file"
                        name="imgsix"
                        onChange={preloadImgsix}
                      />
                      <label htmlFor="icon-button-file">
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <PhotoCamera />
                        </IconButton>
                      </label>
                    </>
                  )}
                </Paper>
              </section>

              <div className="mt-3">
                <TextField
                  className="w-75 "
                  label="Titulo de la publicacion"
                  onChange={(e) => {
                    setPublicacion((prevPub) => ({
                      ...prevPub,
                      ["Titulo"]: e.target.value,
                    }));
                  }}
                  value={publicacion.Titulo}
                />

                <FormControl
                  className=""
                  style={{ width: "15%", marginLeft: "10%" }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Tipo de producto
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      setPublicacion((prevPub) => ({
                        ...prevPub,
                        ["Tipo"]: e.target.value,
                      }));
                    }}
                    value={publicacion.Tipo}
                  >
                  <MenuItem value={'Autos,Motos y otros'}>Autos,Motos y otros</MenuItem>
                  <MenuItem value={'Celulares y Telefonìa'}>Celulares y Telefonìa</MenuItem>
                  <MenuItem value={'Inmuebles'}>Inmuebles</MenuItem>
                  <MenuItem value={'Herramientas'}>Herramientas</MenuItem>
                  <MenuItem value={'Electrónica, Audio y Video'}>Electrónica, Audio y Video</MenuItem>
                  <MenuItem value={'Deporte y fitness'}>Deporte y fitness</MenuItem>
                  <MenuItem value={'Computación'}>Computación</MenuItem>
                  <MenuItem value={'Consolas y Videojuegos'}>Consolas y Videojuegos</MenuItem>
                  <MenuItem value={'Accesorios para vehìculos'}>Accesorios para vehìculos</MenuItem>
                  <MenuItem value={'Electrodomèsticos'}>Electrodomèsticos</MenuItem>
                  <MenuItem value={'Belleza y cuidado personal'}>Belleza y cuidado personal</MenuItem>
                  <MenuItem value={'Juegos y juguetes'}>Juegos y juguetes</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="mt-3 mb-3">
                <textarea
                  className="w-100 p-2"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Informacion de la publicacion"
                  onChange={(e) => {
                    setPublicacion((prevPub) => ({
                      ...prevPub,
                      ["Informacion"]: e.target.value,
                    }));
                  }}
                  value={publicacion.Informacion}
                ></textarea>
              </div>
            </section>
          </section>

          <h3 className="text-start section-box-caracteristics ">
            Características{" "}
          </h3>
          <section className="section-box-caracteristics d-flex align-items-center ">
            <section className="w-50 me-3">
              <div className="d-flex align-items-center ">
                <TextField
                  label="Característica"
                  className="me-3"
                  onChange={(e) => {
                    setCara(e.target.value);
                  }}
                  value={cara}
                />

                <ArrowRightAltIcon className="mt-3" />
                <TextField
                  value={val}
                  label="Valor"
                  className="ms-3"
                  onChange={(e) => {
                    setVal(e.target.value);
                  }}
                />
              </div>

              <div className="d-flex align-items-center">
                <TextField
                  value={cara_dos}
                  label="Característica"
                  className="me-3"
                  onChange={(e) => {
                    setCarados(e.target.value);
                  }}
                />
                <ArrowRightAltIcon className="mt-3" />
                <TextField
                  value={val_dos}
                  label="Valor"
                  className="ms-3"
                  onChange={(e) => {
                    setValdos(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <TextField
                  value={cara_tres}
                  label="Característica"
                  className="me-3"
                  onChange={(e) => {
                    setCaratres(e.target.value);
                  }}
                />
                <ArrowRightAltIcon className="mt-3" />
                <TextField
                  value={cara_tres}
                  label="Valor"
                  className="ms-3"
                  onChange={(e) => {
                    setValtres(e.target.value);
                  }}
                />
              </div>
            </section>
            <section>
              <div className="d-flex align-items-center">
                <TextField
                  value={cara_cuatro}
                  label="Característica"
                  className="me-3"
                  onChange={(e) => {
                    setCaracuatro(e.target.value);
                  }}
                />
                <ArrowRightAltIcon className="mt-3" />
                <TextField
                  value={val_cuatro}
                  label="Valor"
                  className="ms-3"
                  onChange={(e) => {
                    setValcuatro(e.target.value);
                  }}
                />
              </div>

              <div className="d-flex align-items-center">
                <TextField
                  value={cara_cinco}
                  label="Característica"
                  className="me-3"
                  onChange={(e) => {
                    setCaracinco(e.target.value);
                  }}
                />
                <ArrowRightAltIcon className="mt-3" />
                <TextField
                  value={val_cinco}
                  label="Valor"
                  className="ms-3"
                  onChange={(e) => {
                    setValcinco(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex align-items-center">
                <TextField
                  value={cara_seis}
                  label="Característica"
                  className="me-3"
                  onChange={(e) => {
                    setCaraseis(e.target.value);
                  }}
                />
                <ArrowRightAltIcon className="mt-3" />
                <TextField
                  value={val_seis}
                  label="Valor"
                  className="ms-3"
                  onChange={(e) => {
                    setValseis(e.target.value);
                  }}
                />
              </div>
            </section>
          </section>

          <section className="section-box-vendor text-start  d-flex  align-items-center justify-content-between   mt-4 ">
            <div calssName="w-75">
              <InputLabel
                htmlFor="filled-adornment-amount"
                className="mt-3 mb-3 "
              >
                Precio del producto
              </InputLabel>
              <OutlinedInput
                id="filled-adornment-amount"
                value={publicacion.Precio}
                onChange={(e) => {
                  setPublicacion((prevPub) => ({
                    ...prevPub,
                    ["Precio"]: e.target.value,
                  }));
                }}
                className="ms-auto"
                startAdornment={
                  <InputAdornment position="start" className="">
                    $
                  </InputAdornment>
                }
              />
            </div>
            <div>
              <Button
                variant="contained"
                size="small"
                className={"button-update d-block ms-auto p-3 mt-5"}
                onClick={submitUpdate}
              >
                Actualizar
              </Button>
            </div>
          </section>
        </form>
      )}
    </section>
  );
}

/**
 *         <section className="section-box-vendor  text-center  mt-4 ">
          <h3 className='mt-4 text-start  mb-4'>Condiciones/Politicas  para publicar</h3>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox onChange={(e) => { setCondition(e.target.value); }} />}
                label="Condiciones  de las publicaciones"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                The click event of the nested action will propagate up and expand the accordion unless
                you explicitly stop it.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
              aria-controls="additional-actions1-content"
              id="additional-actions1-header"
            >
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox onChange={(e) => { setConditionsec(e.target.value); }} />}
                label="Condiciones  de las publicaciones"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="textSecondary">
                The click event of the nested action will propagate up and expand the accordion unless
                you explicitly stop it.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </section>
 */
