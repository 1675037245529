import React, { useEffect } from "react";
import "./politicas.css";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";

export default function Politicas() {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["auth_user"]);

  useEffect(() => {
    if (!cookies.auth_user) {
      history.push("/login");
    }
  });

  return (
    <section className="box-politicas">
      <h3 className="pt-5 tit-pol">POLÍTICA DE PRIVACIDAD.</h3>
      <p className="pt-1">
      La presente Política de Privacidad describe cómo ID E-Commerce trata y protege los datos de los usuarios registrados en esta página web.
      </p>
      <h6 className="pt-2">Recopilación y uso de datos. </h6>
      <p className="pt-1">
      ID E-Commerce obtiene los datos de los usuarios a través de mecanismos automatizados y mediante peticiones de registro.
      </p>
      <p className="pt-1">
      Los mecanismos automatizados generan registros de actividades de los usuarios, tendientes a establecer patrones de actividad y tráfico al interior del sitio. Se trata de información sobre la URL y nombre de dominio Agit log desde la cual se accede al sitio web, el número IP y browser que utiliza el usuario, el día y hora de acceso al sitio.
      </p>
      <p className="pt-1">
      Esta información no es registrada en forma personalizada e ID E-Commerce generalmente la borra después de 24 meses.
      </p>
      <p className="pt-1">
      Las peticiones de registro a los usuarios tienen por objeto recopilar básicamente su nombre, teléfono, casilla electrónica y domicilio. En el caso de algunos usuarios, para efectos de corroborar la información entregada, ID E-Commerce puede solicitar adicionalmente su número de Run o cualquier antecedente que a juicio de ID E-Commerce sea necesario solicitar para poder publicar la información y el aviso enviado por el usuario en la página web. Esta información es recopilada y se obtiene solo si el usuario lo ha autorizado explícitamente.
      </p>
      <p className="pt-1">
      ID E-Commerce recopila esta información para identificar al usuario que establece una relación con el interesado en su producto a través de la página web de ID E-Commerce y poder prestarle los servicios asociados al uso de este. En el mismo sentido, ID E-Commerce utiliza cookies para recolectar información personal de los usuarios con el objeto de hacerles más eficiente el uso de nuestra página web.
      </p>
      <p className="pt-1">
      La información personal recogida por ID E-Commerce, es usada como parte de sus actividades normales, pero ID E-Commerce no venderá, alquilará ni negociará con otras empresas la información personal de los Usuarios.
      </p>
      <h6 className="pt-2">Mecanismo de seguridad.</h6>
      <p className="pt-1">
      ID E-Commerce ha adoptado niveles de seguridad de protección de los datos personales legalmente requeridos, y ha instalado todos los medios y medidas técnicas a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados y entregados en la página web de ID E-Commerce. Sin perjuicio de lo anterior, el Usuario debe ser consciente de que las medidas de seguridad en Internet no son infalibles y que pese a los esfuerzos desplegados y resguardos tomados por ID E-Commerce, pueden de todos modos, suscitarse eventos no deseados.
      </p>
      <p className="pt-1">
      Por lo tanto, ID E-Commerce ha implementado medidas apropiadas de carácter técnico y organizativo que están diseñadas para garantizar la seguridad de la información personal frente a pérdidas accidentales y frente al acceso, uso, alteración o divulgación no autorizados. En particular, todos los datos de la cuenta y de la clave de acceso están encriptados. 
      </p>
      <p className="pt-1">
      Pese a todo lo anteriormente señalado, Internet es un sistema abierto e ID E-Commerce no puede garantizar que terceras partes no autorizadas ya sea de forma fortuita o premeditadamente, nunca podrán romper estas medidas o usar su información personal para fines indebidos.
      </p>
      <h6 className="pt-2">Calidad de la información y autorización.</h6>

      <p className="pt-1">
      ID E-Commerce recolecta, mantiene, usa, pública o distribuye la información personal vinculada con los usuarios, verificando que los datos sean correctos, completos y adecuados para cumplir con los fines para los que serán utilizados.
      </p>
      <p className="pt-1">
      El usuario al usar la plataforma autoriza en forma expresa a ID E-Commerce para comunicar sus datos al Ministerio Público, Tribunales, Policía de Investigaciones (PDI), Carabineros de Chile y demás autoridades que requieran dicha información, sea en el marco de una investigación, por razones de interés público, o de cualquier otra manera, siempre con pleno resguardo a la Ley 19.628 sobre Protección a la Vida Privada o la legislación y normativa que la reemplace.
      </p>

      <h6 className="pt-2">
      Envío de información publicitaria al correo electrónico
      </h6>

      <p className="pt-1">
      La aceptación de las condiciones de uso y política de privacidad, ligada al uso de cualquiera de los servicios de ID E-Commerce implica la autorización expresa en favor de ID E-Commerce, para el envío de información comercial a la dirección de correo electrónico señalada en la petición de registro, como usuario de la página web. El asentimiento comprende el envío de publicidad comercial y publicitaria relacionada con las actividades, productos y servicios de ID E-Commerce, sea por la empresa o sus proveedores especialmente contratados, quienes podrán tratar los datos personales de los usuarios de ID E-Commerce para este solo efecto.
      </p>
      <h6 className="pt-2">Verificación, corrección y eliminación de datos</h6>

      <p className="pt-1">
      En todo caso los usuarios podrán verificar, corregir y/o eliminar los datos que hayan registrado en la página web de ID E-Commerce, así como solicitar se deje sin efecto la autorización indicada en el acápite anterior. Para cualquiera de estas peticiones el usuario deberá constatarse con administrador@idecommerce.cl
      </p>
      <p className="pt-1">
      Hecha la petición ID E-Commerce confirmará al usuario su recepción y la verificación, corrección y eliminación practicada según la solicitud realizada.
      </p>

      <h6 className="pt-2">Redes Sociales.</h6>

      <p className="pt-1">
      ID E-Commerce dispone de un perfil corporativo en las redes sociales como Facebook, Twitter, Instagram y LinkedIn. Así, en virtud de lo dispuesto por la normativa de Protección de Datos de Carácter Personal, ID E-Commerce es “responsable del tratamiento de sus datos” con motivo de la existencia de dichos perfiles en redes sociales, y el SOLO hecho de que el usuario siga a ID E-Commerce, dando un “Like” o un “Me gusta”, o emita cualquier comentario a los contenidos o perfil, se entiende que nuestra página web también pueda seguirlo y que acepta la presente Política de Privacidad. 
      </p>
      <p className="pt-1">
      En calidad de responsable del tratamiento de los datos personales, ID E-Commerce garantiza la confidencialidad en el tratamiento y el cumplimiento de sus derechos, siempre bajo los efectos de las normativas antes mencionadas.
      </p>
      <p className="pt-1">
      Por otra parte, se informa de que se utilizarán estas redes sociales para anunciar las noticias o información relevante relacionada con los servicios que ID E-Commerce ofrece, o bien sobre temas que sean de interés del público. Usando las funcionalidades de dichas plataformas, es posible que los Usuarios, reciban en su muro o en su perfil información relacionada con los servicios ofrecidos por ID E-Commerce.
      </p>
      <p className="pt-1">
      Se informa que no existe ningún vínculo entre ID E-Commerce y dichas redes sociales, por lo que, al utilizarlas, el Usuario aceptará su política de uso y condiciones una vez acceda a las mismas y/o valide sus avisos, términos y condiciones en el procedimiento de registro, no siendo responsable ID E-Commerce por el uso o tratamiento de sus datos que se haga fuera de la estricta relación y prestación de servicios indicados en la presente Política de Privacidad
      </p>
      <h6 className="pt-2">Derechos del usuario. </h6>

      <p className="pt-1">
      Toda persona que facilite sus datos a ID E-Commerce tiene los siguientes derechos: 
      </p>
      <p className="pt-1">
        Para proteger su privacidad y seguridad, E-Commerce tomara medidas
        razonables para comprobar su identidad antes de darle acceso a los
        propios datos del interesado o permitir que los corrija.{" "}
      </p>

      <p className="pt-1"> a) Las personas interesadas o Usuarios tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión o eliminación cuando, entre otros motivos, los datos ya no sean necesarios para los fines para los que fueron recogidos o publicados; </p>
      <p className="pt-1"> b) Los interesados podrán solicitar la limitación del tratamiento de sus datos o su portabilidad, en cuyo caso únicamente se conservarán para el ejercicio o la defensa de reclamaciones; </p>
      <p className="pt-1"> c) En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos; </p>
      <p className="pt-1"> d) El Usuario podrá ejercitar en cualquier momento sus derechos de acceso, rectificación, cancelación y oposición al tratamiento de sus Datos Personales, mediante solicitud realizada a la siguiente dirección de email: administrador@idecommerce.cl</p>
      <p className="pt-1"> Para proteger su privacidad y seguridad, ID E-Commerce tomará medidas razonables para comprobar su identidad antes de darle acceso a los propios datos del interesado o permitir que los corrija.</p>

      <h6 className="pt-2">Menores de edad.</h6>

      <p className="pt-1">
      La página web de ID E-Commerce no está diseñado para ser utilizada por personas menores de 18 años.
      </p>
      <h6 className="pt-2">Exclusión de responsabilidad</h6>

      <p className="pt-1">
      Habiendo aceptado “Las condiciones generales” y, por tanto, también la presente Política de Privacidad, el uso que el Usuario realice de este sitio web es bajo su propio riesgo. ID E-Commerce no será responsable frente a cualquier daño o perjuicio que cualquier persona pueda sufrir a causa de una violación de la confidencialidad causada por el uso de esta página web o de cualquier información transmitida por intermedio de este sitio, ni asume responsabilidad alguna por cualquier daño a su equipo o cualquier pérdida de información, como es señalado en las condiciones generales.
      </p>

      <h6 className="pt-2">Cambios a la Política de Privacidad</h6>
      <p className="pt-1">
      ID E-Commerce se reserva el derecho a modificar la presente Política de Privacidad, con el objeto de adaptarla a cambios legislativos o jurisprudenciales, a prácticas generales de la industria o a políticas internas de ID E-Commerce. Las modificaciones a la Política de Privacidad serán anunciadas a los usuarios en la misma forma que cualquier cambio a las “Condiciones generales” para el uso del sitio ID E-Commerce.
      </p>
      <p className="pt-1">
      En Chillán, con fecha 10 de noviembre del año 2021.
      </p>
    </section>
  );
}
