import { Avatar, Paper, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useCookies } from "react-cookie";
import './perfil.css';
import { makeStyles } from '@material-ui/core/styles';

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import { Doughnut, Line } from 'react-chartjs-2';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { CircularProgress } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
    },
}));

const options_line = {
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
    },
};



export default function Perfil(props) {

    const [cookies, setCookie, removeCookie] = useCookies(["email"]);
    const [rendered, setRendered] = useState(false);
    const [disBtn, setdisBtn] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    
    let history = useHistory();

    const [data_line_records,setDataLineRecords] = useState([
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0,
        0
    ]);

    const data_line = {
        labels: ['1', '2', '3', '4', '5', '6','7','8','9','10','11','12'],
        datasets: [
            {
                label: 'Cantidad de publicaciones por mes',
                data: [...data_line_records],
                fill: true,
                backgroundColor: 'rgb(255, 153, 0)',
                borderColor: 'rgba(255, 99, 132, 0.2)',
            },
        ],
    };

   


    useEffect(() => {
        if(cookies.auth_user){
            if (!rendered) {
                setShowSpinner(true);
                const endpoint = 'https://idecommerce.cl/services/api/get/user-info';
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + cookies.auth_user,
                    },
                }
                axios.post(endpoint, {}, config)
                    .then(result => {
                        setUser(result.data);
                        console.log(result);
                        result.data.statistics.map((item) =>{
                            data_line_records[item['Month(created_at)']-1] = item.countpubs;
                        })
                    });
                setTimeout(() => {
                    setRendered(true);
                    setShowSpinner(false);
                }, 3000);
            }
        }else{
            history.push('/login');
        }
    }, [cookies.auth_user, data_line_records, history, rendered])
    

    const loadImg = (e) => {
        const endpoint = 'https://idecommerce.cl/services/api/update/user-logo';

        var bodyImgs = new FormData();
        bodyImgs.append('file', e.target.files[0]);

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies.auth_user,
            },
        }
        axios.post(endpoint, bodyImgs, config)
            .then(res => {
                removeCookie("avatar");
                setCookie("avatar", res.data.url);
                setRendered(false);

            });

    }

    const  handleValues=(e)=>{
        if(disBtn){
            setdisBtn(false);
        }
        const  value = e.currentTarget.value;
        setUser({...user,[e.target.name]: value});
    }

    const [user, setUser] = useState(null);

    const  submitUser = (e)=>{
        e.preventDefault();
        const endpoint = 'https://idecommerce.cl/services/api/update/user-info';
        const  data  =user;
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies.auth_user,
            },
        }
        axios.post(endpoint,data,config)
        .then(res => {alert(res.data.status)}); 
    }

    const classes = useStyles();
    return (<section className='box-perfil  '>
            {showSpinner && (
                <div className="box-spinner d-flex justify-content-center align-items-center w-100">
                    <CircularProgress  size={60} />
                </div>
        )}
        {rendered && user &&
            <Paper className=''>
                <div className="box-perfil-header  position-relative ">
                    <div className="box-perfil-header-head  w-100"></div>
                    <input
                        accept="image/*"
                        className={"d-none"}
                        id="icon-button-file"
                        type="file"
                        name="imgpre"
                        onChange={loadImg}
                    />
                    <label htmlFor="icon-button-file" className='label-button-file position-relative'>
                        <Avatar
                            alt="Logo user"
                            src={user.logo ? 'https://idecommerce.cl/services/api/storage/' + user.logo : ''}
                            className={"box-perfil-header-avatar over-pointer    ms-auto  me-auto " + classes.large}
                        />
                        <div className='header-avatar-edit'>
                            <EditIcon  />
                        </div>
                        
                    </label>
                    <p className=' p-0 m-2'>{user.name}</p>
                    <ShoppingCartIcon />

                </div>
                <article className='box-perfil-aditional d-flex justify-content-center '>
                    <p><StoreIcon></StoreIcon> Publicaciones  Totales : {user.pubs_totales}</p>
                    <p className='ms-auto'>Usuario creado </p>
                </article>
                <article className='box-perfil-info  text-start p-5  pt-3 '>
                    <h3 >Información del usuario</h3>
                    <article className='mt-3'>
                        <section>
                            <TextField
                                value={user.name}
                                label="Nombre"
                                className="margen-text2"
                                name='name'
                                onChange={handleValues}
                            />
                            
                            <TextField
                                value={user.nacimiento}
                                label="Fecha de nacimiento"
                                className="ms-3 margen-text"
                                name='nacimiento'
                                onChange={handleValues}
                            />
                            <TextField
                                value={user.genero}
                                label="Genero"
                                className="ms-3 margen-text"
                                name="genero"
                                onChange={handleValues}
                            />
                        </section>

                    </article>
                    <hr className='mt-4 mb-0' />
                    <h5 className='mt-3'>Conctacto</h5>
                    <section className=''>
                        <TextField
                            value={user.telefono}
                            label="Teléfono"
                            className="margen-text2"
                            name='telefono'
                            onChange={handleValues}
                        />
                        <TextField
                            value={user.email}
                            label="Correo electronico"
                            className="ms-3 margen-text"
                            disabled
                        />

                    </section>

                    <hr className='mt-4' />


                    <h5 className='mt-3'>Ubicación</h5>
                    <article className=''>
                        <section>
                            <TextField
                                value={user.direccion}
                                label="Dirección"
                                className=" margen-text"
                                name='direccion'
                                onChange={handleValues}

                            />
                            <TextField
                                value={user.ciudad}
                                label="Ciudad"
                                name='ciudad'
                                className="ms-3 margen-text2"
                                onChange={handleValues}

                            />
                            <TextField
                                value={user.pais}
                                label="Pais"
                                name='pais'
                                onChange={handleValues}
                                className=" ms-3 margen-text2"
                            />
                        </section>
                    </article>
                    
                    <button disabled={disBtn} onClick={submitUser} className=' button-update btn ms-auto  d-block mt-4 ' variant="warning">Actualizar</button>
                    <div className='d-flex mt-5 align-items-center borrar-categoria'>
                        <div className='w-100 text-center'>
                            <h5 className=''>Publicaciones creadas</h5>
                        </div>
                    </div>

                    <article className='mt-3 text-center   d-flex align-items-center  borrar-categoria'>
                        <div className=' '>

                        </div>
                        <div className=' w-75 ms-auto me-auto'>
                            <Line  data={data_line} options={options_line} />
                        </div>

                    </article>
                </article>
            </Paper>}
    </section>);

}
