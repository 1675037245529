import React, { useEffect, useState } from "react";
import "./soporte.css";
import { Form, Button, ToastContainer, Toast } from "react-bootstrap";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";
import axios from "axios";
export default function Soporte() {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["auth_user"]);
  const [message, setMessage] = useState("");
  const [asunto, setAsunto] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [messageToast, setMessageToast] = useState("");

  useEffect(() => {
    if (!cookies.auth_user) {
      history.push("/login");
    }
  });
  const submitSendSoporte = (e) => {
    e.preventDefault();
    let url = "https://idecommerce.cl/services/api/send/to/support";
    const config = {
      headers: {
        Authorization: "Bearer " + cookies.auth_user,
      },
    };
    axios
      .post(
        url,
        {
          message: message,
          asunto: asunto,
        },
        config
      )
      .then((response) => {
        setShowToast(true);
        setMessageToast(response.data.result);
      })
      .catch((error) => {
        setShowToast(true);
      });
  };

  return (
    <section className="box-soporte  mb-5 position-relative">
      {showToast && (
        <ToastContainer className="p-3" position={"top-center"}>
          <Toast
            bg={"success"}
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={7000}
            autohide
          >
            <Toast.Body className="text-center text-white">
              {messageToast}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <p className="p-contacto">Contáctanos </p>
      <Form className=" form-contacto ">
        <p style={{ fontSize: "1.1rem" }}>
          Bienvenidos a la seccion de Soporte tecnico , aqui por favor
          indiquenos cual es su problema y a la brevedad nos pondremos en
          contacto.
        </p>
        <p>Gracias ID-Ecommerce</p>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Control
            className=" formularioCon"
            type="text"
            placeholder="Asunto"
            onChange={(e) => {
              setAsunto(e.target.value);
            }}
          />
        </Form.Group>

        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Control
            className=" formularioCon"
            as="textarea"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            rows={3}
            placeholder="Escribe el mensaje aqui..."
          />
        </Form.Group>

        <Button className=" botonCon " onClick={submitSendSoporte}>
          Enviar
        </Button>
      </Form>
    </section>
  );
}
