import React, { useEffect, useState } from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import './publicar.css'

const Carrusel = (props) => {
  const images = props.listImgs.map((img) => ({
    src: `https://idecommerce.cl/services/api/storage/${img.url}`
  }));

  return (
    <Carousel className='carrusel' images={images} />
  );
};

export default Carrusel;