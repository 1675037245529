import { CircularProgress, Fab, IconButton } from "@material-ui/core";
import React, { Component, useEffect } from "react";
import CardA from "./cardA";
import "./misAvisos.css";
import AddIcon from "@material-ui/icons/Add";
import MarcadorRapido from "../../MarcadoRapido/marcadorapido";
import axios from "axios";
import Tooltip from '@material-ui/core/Tooltip';
import { useCookies } from "react-cookie";
import { useState } from "react";
import { Link } from "react-router-dom";
import PublicacionEdit from "../../Publicacionedit/publicacionedit";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useHistory } from 'react-router';


export default function MisAvisos() {
  const [mypublics, setMypublics] = useState([]);
  const [geted, setGeted] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["auth_user"]);

  const [publicacion, setPublicacion] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [editmode, setEditmode] = useState(false);

  const getMyPublic = async (e) => {
    setShowSpinner(true);
    let url = "https://idecommerce.cl/services/api/get/publicacionuser";
    await axios
      .post(
        url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.auth_user,
          },
        }
      )
      .then((response) => {
        let  da =  [];
        for (const key in response.data) {   
            const element = response.data[key];
            da.push(element);
        }
        setMypublics(da);
      });
      setTimeout(() => {
        setShowSpinner(false);
      }, 2000);
  };

  const  enabledEdit= (pub)=>{
     setPublicacion(pub);
     setEditmode(true);
  }

  const  closeEdit = ()=>{setEditmode(false);}
 
  const history = useHistory();


  useEffect(() => {
    if (!cookies.auth_user)  history.push('/login');
    if (!geted) {
      getMyPublic();
      setGeted(true);
    }
  }, [mypublics]);




  return (
    <section className="saecMis">
      
      {showSpinner && (
              <div className="box-spinner d-flex justify-content-center align-items-center w-100">
                <CircularProgress size={60} />
              </div>
      )}
      
      { !showSpinner &&  !editmode && mypublics.length > 0 && <h3 className="hTresInicio">PUBLICACIONES RECIENTES</h3>}
      { !showSpinner &&  !editmode && mypublics.length > 0 &&
        mypublics.map((item) => {
          return (
            <CardA
              id={item.id}
              key={item.id}
              titulo={item.Titulo}
              precio={item.Precio}
              publicado={item.created_at}
              enabledEdit={enabledEdit}
              publicacion={item}
              getMyPublic={getMyPublic}
              caracteristicas={item.caracteristicas}
            />
          );
        })}

      {!showSpinner && mypublics.length < 1 && (
        <div style={{height:'65vh'}} className=" d-flex justify-content-center align-items-center">
          <div>
          <p className='display-4 mb-5'>Todavia no creaste ninguna publicacion </p>
          <Link to="/publicar">
          <Tooltip title="Crear publicacion" aria-label="Crear publicacion">
            <Fab color="primary">
              <AddIcon />
            </Fab>
          </Tooltip>
          </Link>
          </div>
       
        </div>
      )}

      {editmode  &&   <div className='w-100  text-start '>  <IconButton aria-label="Volver atras" className='bg-warning back-icon' onClick={closeEdit}>
                <KeyboardBackspaceIcon />
      </IconButton> </div>}

      {editmode  &&  <PublicacionEdit publicacion={publicacion} />}

    </section>
  );
}
