import { Chip } from "@material-ui/core";
import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./categorias.css";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Row>
          {props.filtroTipo &&
            props.filtroTipo.map((tipo) => {
              return (
                <Col xs={4}>
                  <Chip
                    color="primary"
                    size="small"
                    label={tipo.name}
                    id={tipo.id}
                    onClick={(e) => {
                      e.preventDefault();
                      props.onHide();
                      props.handlerFiltro(e);
                    }}
                    className="chip-cat-uno"
                  />
                </Col>
              );
            })}
          {props.filtroMarca &&
            props.filtroMarca.map((tipo) => {
              return (
                <Col xs={4}>
                  <Chip
                    color="primary"
                    size="small"
                    label={tipo.name}
                    id={tipo.id}
                    onClick={(e) => {
                      e.preventDefault();
                      props.onHide();
                      props.handlerFiltro(e);
                    }}
                    className="chip-cat-uno"
                  />
                </Col>
              );
            })}
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default function ModalFiltro(props) {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Link className="link-modal" onClick={() => setModalShow(true)}>
        ver más
      </Link>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        {...props}
      />
    </>
  );
}
