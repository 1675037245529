import React, {  } from 'react'
import { Col, Row } from 'react-bootstrap';
import Pedido from '../Pedido/pedido';
import Steppe from '../Steppe/steppe';

export default function Carrito(){
    return(
        <Row className='m-0 '>
            <Col sx={6}>
                <Steppe/>
            </Col>
            <Col sx={6}>
                <Pedido/>
            </Col>
        </Row>
    );
}