import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './steppe.css'
import { Col, Row } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '10%'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
 
}));

function getSteps() {
  return ['Select master blaster campaign settings', 'Create an ad group', 'Create an ad'];
}

function getStepContent(stepIndex) {
  
  switch (stepIndex) {
    case 0:
      return (
            <section>
              <h4 className='titulo-step'>DATOS DE FACUTIRACIÓN</h4>
              <hr />
              <div className='sec-div'>
                <h6 className='text-h6'>Cliente*</h6>
                <form className='w-100' noValidate autoComplete="off">
                <TextField id="standard-required" label="Nombre" type="search" className='textF-step'/>
                <TextField id="standard-required" label="Apellido" type="search" className='textF-step' />
                </form>
              </div>
              <div className='sec-div'>
                <h6 className='text-h6'>Documento*</h6>
                <form className='w-100' noValidate autoComplete="off">
                <TextField id="standard-required" label="Doc.Identidad" type="number"  className='textF-step'/>
                <TextField id="standard-required" label="Localidad" type="search"  className='textF-step' />
                </form>
              </div>
              <div className='sec-div'>
                <h6 className='text-h6'>Teléfono*</h6>
                <form className='w-100' noValidate autoComplete="off">
                <TextField id="standard-required" label="094 234 234" type="number" helperText="Número de Celular. Lo solicitamos para contactarnos ante duda o problema con el pedido." className='textF-step-dos'/>
                </form>
              </div>
          </section>
      );
    case 1:
      
      return (
        <section>
        <h4 className='titulo-step'>DIRECCIÓN DE ENVÍO</h4>
        <hr />
        <div className='sec-div'>
          <h6 className='text-h6'>Departamento*</h6>
          <FormControl className='w-100'>
            <InputLabel id="demo-simple-select-label">Departamento</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"

            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className='sec-div'>
          <h6 className='text-h6'>Localidad*</h6>
          <FormControl className='w-100'>
            <InputLabel id="demo-simple-select-label">Localidad</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"

            >
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </div>
        
        <div className='sec-div'>
          <h6 className='text-h6'>Calle*</h6>
          <form className='w-100' noValidate autoComplete="off">
          <TextField id="standard-required" label="Calle" type="search"  className='textF-step-dos'/>
          </form>
        </div>

        <div className='sec-div'>
          <h6 className='text-h6'>Nro. de puerta*</h6>
          <form className='w-100' noValidate autoComplete="off">
          <TextField id="standard-required" label="ej: 3050" type="number"  className='textF-step-dos'/>
          </form>
        </div>

        <div className='sec-div'>
          <h6 className='text-h6'>Apartamento*</h6>
          <form className='w-100' noValidate autoComplete="off">
          <TextField id="standard-required" label="ej: 401" type="number"  className='textF-step-dos'/>
          </form>
        </div>

        <div className='sec-div'>
          <h6 className='text-h6'>Código postal*</h6>
          <form className='w-100' noValidate autoComplete="off">
          <TextField id="standard-required" type="number"  className='textF-step-dos'/>
          </form>
        </div>

        <div className='mt-5'>
          <h6 className='text-h6 '>Aclaraciones*</h6>
          <form className='w-100' noValidate autoComplete="off">
          <TextField
            id="outlined-multiline-static"
            label="Ej: Aclaración sobre la numeración, esquinas, etc."
            multiline
            rows={4}
            variant="outlined"
            className='textF-step-dos'
          />
          </form>
        </div>
    </section>
      );
    case 2:
      return 'This is the bit I really care about!';
    default:
      return 'Unknown stepIndex';
  }
}

export default function Steppe() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
