import React, { useEffect, useState } from "react";
import "./publicar.css";
import { Col, Row } from "react-bootstrap";
import Carrusel from "./carrusel";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import ScheduleIcon from "@material-ui/icons/Schedule";
import Caracteristicas from "./caracteristicas";
import axios from "axios";

export default function Publicar(props) {
  const [Publicacion, setPublicacion] = useState({});
  const [User, setUser] = useState({});
  const [rendered, setRendered] = useState(false);
  const [Caracteristics, setCaracteristics] = useState([])
  const [Multimedia, setMultimedia] = useState([])
    
  const  getPublicacion = async (e) => {
    let url =
      "https://idecommerce.cl/services/api/get/publicacion";
      const  params ={
          "idPubli":props.match.params.idPublic
      }
    axios
      .post(url, 
        params,{
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
            setPublicacion(response.data);
            setUser(response.data.user);
            setCaracteristics(response.data.caracteristicas);
            setMultimedia(response.data.multimedias);
      });
  };
  useEffect(() => {
      if(!rendered){
        getPublicacion();
        setRendered(true);
      }
  }, [Publicacion]);
  
  return (
    <section className="secP">
      <Row className="m-0 p-0 rowPrincipal  ">
        <Col sm={6}>
          {Multimedia.length>0 &&  <Carrusel listImgs={Multimedia} />}
        </Col>
        <Col sm={6}>
          <div>
            <h5 class="card-title">
              {Publicacion.Titulo}
            </h5>
            
            <h3 class="card-text">$ {Publicacion.Precio}</h3>
          </div>

          <Row className="m-0 p-0 mt-4 ">
            
            <Col sm={6} >
              <div>
                <h5>Ubicación</h5>
                <p class="card-text">
                  <LocationOnIcon />
                  <small class="text-muted ms-2">{User.direccion}</small>
                </p>
              </div>
              <div>
                <h5 className="mt-4">Información de Contacto</h5>
                <p class="card-text">
                  <PhoneAndroidIcon />
                  <small class="text-muted ms-2">{User.telefono}</small>
                </p>
                <p class="card-text">
                  <MailOutlineIcon />
                  <small class="text-muted ms-2">{User.email}</small>
                </p>
              </div>
              <div>
                <h5 className="mt-4">Información de Vendedor</h5>
                <p class="card-text">
                  <PhoneAndroidIcon />
                  <small class="text-muted ms-2">Nombre: {User.name} </small>
                </p>
                <p class="card-text">
                  <MailOutlineIcon />
                  <small class="text-muted ms-2">{User.email}</small>
                </p>
                <p class="card-text">
                  <ScheduleIcon />
                  <small class="text-muted ms-2">Publicando desde {User.created_at}</small>
                </p>
              </div>
            </Col>
            <Col sm={6} >
              <h5>Caracteristicas</h5>
              {Caracteristics.length>0  && Caracteristics.map(item =>{
                  return (<Caracteristicas  name={item.name} valor={item.valor} />);
              })}
      
        </Col>
          </Row>
        </Col>
       
      </Row>
      <div className="divDes">
        <h5>Descripción</h5>
        <p class="card-text pb-3">
          <small class="text-muted">
                {Publicacion.Informacion}
          </small>
        </p>
      </div>
    </section>
  );
}


/*<p class="card-text">
              <small class="text-muted">Publicado hace {Publicacion.created_at}</small>
            </p>*/ 