import React, { Component } from 'react';
import './publicar.css'
import  { Col, Row } from 'react-bootstrap';


class Caracteristicas extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render(props) { 
        return ( 
            <Row className='mt-2'> 
                <Col sm={6} className='colUno'>
                    <p class="card-text"><small class="text-muted">{this.props.name}</small></p>
                </Col>
                <Col sm={6} className='colDos'>
                    <p class="card-text"><small class="text-muted">{this.props.valor}</small></p>
                </Col>
            </Row>
         );
    }
}
 
export default Caracteristicas;