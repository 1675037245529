import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withWidth } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const useStyles = makeStyles({
  root: {
    textAlign: "left",
  },
  media: {
    height: 140,
  },
  botonI: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    textDecoration: 'none'
  },
});

export default function MediaCard(props) {
  const classes = useStyles();
  let time = props.created_at;
  let date = time.split("T");
  let fecha = date[0];
  let fechaSort = fecha.split('-');
  let ano = fechaSort[0];
  let mes = fechaSort[1];
  let dia = fechaSort[2];
  fecha =  dia +'/'+mes+'/'+ano;
  
  return (
    <Card className={classes.root + " box-card-item"}>
      <Link to={'/Publicacion/' + props.id} className={classes.botonI + " h-100"}>
      <CardActionArea className="h-100">
        <CardMedia
          className={classes.media}
          image={'https://idecommerce.cl/services/api/storage/'+props.publicacion.multimedia.url}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography variant="body1" color="textSecondary" component="p">
            {props.Titulo}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2">
           $ {props.Precio} 
          </Typography>
        </CardContent>
      </CardActionArea>
      </Link>
    </Card>
  );
}


/* <Typography variant="body2" className='text-end' color="textSecondary" component="p">
            {fecha}
          </Typography>*/