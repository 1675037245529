import React, { forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Button } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import { DeleteForever, DeleteOutline, PhotoCamera } from "@material-ui/icons";
import axios from "axios";
import { useCookies } from "react-cookie";


const useStyles = makeStyles({
  fullList: {
    width: "auto",
  },
});

const ModalCustom = forwardRef((props, ref) => {
  const [cookies, setCookie, removeCookie] = useCookies(["email"]);

  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  useImperativeHandle(ref, (anchor, open) => ({
    toggleDrawerChild(anchor, open) {
      setState({ ...state, [anchor]: open });
    },
  }));

  const submitUploadImg = (e) => {
      e.preventDefault();
      let endpoint = 'https://idecommerce.cl/services/api/update/publicacion/multimedia';
      
      var bodyImgs = new FormData();
      bodyImgs.append('file',e.target.files[0]);
      bodyImgs.append('multimediaUrl',props.currentImg);

      const config = {
        headers: {
          Authorization: "Bearer " + cookies.auth_user,
        },
      };
      axios.post(endpoint, bodyImgs, config)
      .then(res=>{
          alert(  JSON.stringify(res.data.result));
      });
      
  };

  const submitDeleteImg = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={(e) => {
              e.preventDefault();
              setState({ ...state, ["left"]: false });
            }}
          >
            <article className="p-3 text-center" style={{ width: "300px" }}>
              <img className="w-75" src={props.currentImg} />
              <hr />
              <input
                accept="image/*"
                className={"d-none"}
                id="btn-file"
                type="file"
                name="imgpre"
                onChange={submitUploadImg}
              />
              <label htmlFor="btn-file" className="w-100">
                <span className="btn btn-primary w-100">
                  <PhotoCamera /> Actualizar
                </span>
              </label>
              <Button
                variant="danger"
                className="w-100 mt-3"
                onClick={submitDeleteImg}
              >
                <DeleteForever/> Borrar
              </Button>
              <Button
                variant="primary"
                className="w-100 position-absolute bg-danger "
                style={{ left: "0", bottom: "0", borderRadius: "0" }}
                onClick={(e) => {
                  e.preventDefault();
                  setState({ ...state, ["left"]: false });
                }}
              >
                Cerrar
              </Button>
            </article>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
});

export default ModalCustom;
