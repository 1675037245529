import React, { Component } from "react";
import "./inicio.css";
import { Col, Row, Button } from "react-bootstrap";
import DirectionsCarOutlinedIcon from "@material-ui/icons/DirectionsCarOutlined";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import BuildIcon from "@material-ui/icons/Build";
import MicNoneIcon from "@material-ui/icons/MicNone";
import SportsBasketballIcon from "@material-ui/icons/SportsBasketball";
import ComputerIcon from "@material-ui/icons/Computer";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import TwoWheelerIcon from "@material-ui/icons/TwoWheeler";
import KitchenIcon from "@material-ui/icons/Kitchen";
import EmojiNatureIcon from "@material-ui/icons/EmojiNature";
import ExtensionIcon from "@material-ui/icons/Extension";
import CardIni from "./card";
import axios from "axios";

import Pagination from "@material-ui/lab/Pagination";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      publicaciones: [],
      pagina: 1,
      totalPag: 0,
      showSpinner: false,
    };
  }

  getPublicaciones = async (e) => {
    this.setState({ showSpinner: true });
    let url =
      "https://idecommerce.cl/services/api/get/publicaciones?page=" +
      this.state.pagina;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        this.setState({
          publicaciones: response.data.data,
          totalPag: response.data.last_page,
        });
      });

    setTimeout(() => {
      this.setState({ showSpinner: false });
    }, 2000);
  };

  componentDidMount() {
    this.getPublicaciones();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.pagina != this.state.pagina) {
      this.getPublicaciones();
    }
  }

  render() {
    let { publicaciones, showSpinner } = this.state;
    return (
      <>
        <section className="sectionInicio p-3">
          <div className="divInicio mt-4">
            <h1 className="hUnoInicio mb-4">Comenzar a públicar</h1>
            <p className="peInicio mb-4">
              Puedes comenzar a públicar de una forma ágil y sencilla
            </p>
            <Link to="/Publicar">
              <Button variant="outline-light" className="botonAviso">
                PÚBLICAR AVISO
              </Button>
            </Link>
          </div>
        </section>
    

        <section className="sectionInicioDos ">
          <div className="d-flex justify-content-between">
            <h3 className="hTresInicio">PUBLICACIONES RECIENTES</h3>
            <Pagination
              count={this.state.totalPag}
              color="primary"
              className=""
              onChange={(event, value) => {
                this.setState({ pagina: value, showSpinner: true });
                setTimeout(() => {
                  this.setState({ showSpinner: false });
                }, 2000);
              }}
            />
          </div>
          <div className="d-flex w-100 box-card">
            {!showSpinner &&
              publicaciones.length > 0 &&
              publicaciones.map((item) => {
                return (
                  <CardIni
                    id={item.id}
                    Titulo={item.Titulo}
                    Precio={item.Precio}
                    created_at={item.created_at}
                    publicacion={item}
                  />
                );
              })}
            {showSpinner && (
              <div className="box-spinner d-flex justify-content-center align-items-center w-100">
                <CircularProgress size={60} />
              </div>
            )}
          </div>
        </section>


            <section className="sectionInicioDos">
          <h3 className="hTresInicio">CATEGORÍAS PRINCIPALES</h3>
          <div className="p-3">
            <Row className="">
              <Col className="ColInicio border" sm={2}>
                <Link to={"/Categoria/Autos"}>
                  <DirectionsCarOutlinedIcon className="icono" />
                </Link>
                <p>Autos, Motos y Otros</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
                <Link to={"/Categoria/Celulares y Telefonìa"}>
                  <PhoneIphoneIcon className="icono" />
                </Link>
                <p>Celulares y Telefonía</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
                <Link to={"/Categoria/Inmuebles"}>
                  <HomeWorkIcon className="icono" />
                </Link>
                <p>Inmuebles</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
                <Link to={"/Categoria/Herramientas"}>
                  <BuildIcon className="icono" />
                </Link>

                <p>Herramientas</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
                <Link to={"/Categoria/Electrónica, Audio y Video"}>
                  <MicNoneIcon className="icono" />
                </Link>
                <p>Electrónica, Audio y Video</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
                <Link to={"/Categoria/Deporte y fitness"}>
                  <SportsBasketballIcon className="icono" />
                </Link>

                <p>Deporte y fitness</p>
              </Col>
            </Row>
            <Row>
              <Col className="ColInicio border" sm={2}>
                <Link to={"/Categoria/Computacion"}>
                  <ComputerIcon className="icono" />
                </Link>
                <p>Computación</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
              <Link to={"/Categoria/Consolas y Videojuegos"}>

                <SportsEsportsIcon className="icono" />
                </Link>
                <p>Consolas y Videojuegos</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
              <Link to={"/Categoria/Accesorios para vehìculos"}>
                <TwoWheelerIcon className="icono" />
                </Link>
                <p>Accesorios para vehículos</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
              <Link to={"/Categoria/Electrodomèstico"}>
                <KitchenIcon className="icono" />
                </Link>
                <p>Electrodomésticos</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
              <Link to={"/Categoria/Belleza y cuidado personal"}>
                <EmojiNatureIcon className="icono" />
                </Link>

                <p>Belleza y cuidado personal</p>
              </Col>
              <Col className="ColInicio border" sm={2}>
              <Link to={"/Categoria/Juegos y juguetes"}>

                <ExtensionIcon className="icono" />
                </Link>

                <p>Juegos y juguetes</p>
              </Col>
            </Row>
          </div>
        </section>
      </>
    );
  }
}

export default Inicio;
