import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { Avatar } from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router-dom';
import './menu.css'
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import SubjectIcon from '@material-ui/icons/Subject';
import BuildIcon from '@material-ui/icons/Build';
import CloseIcon from '@material-ui/icons/Close';

import  logo  from './Capa 1.png'
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { withStyles } from '@material-ui/styles';


import Badge from '@material-ui/core/Badge';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

export default function MenuUno(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["auth_user"]);
  const [locPub, setLocPub] = useState('');



  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const  history =  useHistory();

  const handleExit = () => {
    handleDrawerClose();
    removeCookie('email');
    removeCookie('name');
    removeCookie('avatar');
    removeCookie('auth_user');
    history.push('/');
  }

  useEffect(() => {
 
    if(props.location.pathname==='/Publicar'){
      setLocPub('link-active');
    }else{
      setLocPub('');
    }
  })

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.title}>
           <Link to="/" className="logo">
                <img src={logo} alt="" className='logo'  />
            </Link>   
          </Typography>
          {cookies.auth_user && ( <div>
             { /* <Link to="/carrito" className='link-carrito'>
                  <ShoppingCartIcon   />
              </Link>*/}
              <Link to="/Publicar" className={"linkpub linkM ms-4 me-4 " + locPub}>
              Públicar
              </Link> 
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              className={clsx(open && classes.hide)}
            >
              <StyledBadge
        overlap="circular"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        variant="dot"
      >
            <Avatar
                src={"https://idecommerce.cl/services/api/storage/" + cookies.avatar}>
            </Avatar>
            </StyledBadge>

            </IconButton>
          
          </div>   )}
          
          

          {!cookies.email && (
            <Link to="/login" className="linkM">
              Iniciar Sesión
            </Link>
          )}
        </Toolbar>
   
      </AppBar>
      
     
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List className='list-menu'>
        {["Perfil", "MisAvisos", "Politicas", "Soporte",  "Cerrar sesión"].map(
          (text, index) => {
            if (text == "Perfil") {
              return (
                <Link to={"/User/" + text} onClick={handleDrawerClose}>
                  <ListItem button key={text}>
                    <ListItemIcon>
                      {index % 2 === 0 ? <PermIdentityIcon /> : <PermIdentityIcon /> }
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                </Link>
              );
            } else if (text == "Cerrar sesión") {
                return   <button className='btn p-0' onClick={handleExit}>
                <ListItem button key={text}>
                  <ListItemIcon>
                    {index % 2 === 0 ? <CloseIcon /> : <CloseIcon /> }
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              </button>
            } else {
              return (
                <Link to={"/" + text} onClick={handleDrawerClose}>
                  <ListItem button key={text}>
                    <ListItemIcon>
                      {index === 1 && <AnnouncementIcon/>}
                      {index === 2 &&  <SubjectIcon /> }
                      {index === 3 &&  <BuildIcon />  }
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                </Link>
              );
            }
          }
        )}
      </List>
  
  
      </Drawer>
    </div>
  );
}
