import axios from "axios";
import React, { Component } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import SnackBarComp from "../../SnackBar/snackbar";
import "./misAvisos.css";

export default function CardA(props) {
  /*let time = props.publicado;
        let date = time.split("T");
        let fecha = date[0];
        let fechaSort = fecha.split('-');
        let ano = fechaSort[0];
        let mes = fechaSort[1];
        let dia = fechaSort[2];
        fecha =  dia +'/'+mes+'/'+ano;*/
  const [Snack, setSnack] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(["email"]);

  const deletePublic = () => {
    let url = "https://idecommerce.cl/services/api/delete/publicacion";
    const params = {
      idPubli: props.id,
    };
    axios
      .post(url, params, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies.auth_user,
        },
      })
      .then((response) => {
        setSnack(true);
        setTimeout(() => {
          props.getMyPublic();
        }, 1000);
      });
  };
  const handleMod = () => {
    props.enabledEdit(props.publicacion);
  };

  return (
    <div class="card mb-3 cardA">
      {Snack && <SnackBarComp severity="success" />}
      <div class="row g-0">
        <div class="col-md-3">
          <img
            src={
              "https://idecommerce.cl/services/api/storage/" +
              props.publicacion.multimedia.url
            }
            class="img-fluid rounded-start imgA"
            alt="..."
          />
        </div>
        <div class="col-md-7 ">
          <div class="card-body pt-0 cardAdos">
            <h5 class="card-title">{props.titulo}</h5>
            <h3 class="card-text">U$S {props.precio}</h3>
          </div>
          <div class="card-body pt-0 cardAdos">
            <small class="text-muted">
              {props.caracteristicas.map((cara) => {
                return (
                  <p className="mb-2">
                    {cara.name} : {cara.valor}
                  </p>
                );
              })}
            </small>
          </div>
        </div>
        <div class="col-md-2 colA">
          <div className="divAA">
            <button
              type="button"
              class="btn btn-primary botonA"
              onClick={handleMod}
            >
              Modificar Publicación
            </button>
            <Link to={"/Publicacion/" + props.id}>
              <button type="button" class="btn btn-success  botonA w-100">
                Ver publicación
              </button>
            </Link>
            <button
              type="button"
              class="btn btn-danger botonA"
              onClick={deletePublic}
            >
              Borrar Publicación
            </button>
          </div>
        </div>
      </div>
      <hr className="mt-5 mb-5" />
    </div>
  );
}
