import React, {  } from 'react'
import './pedido.css'


export default function Pedido(){
    return(
        <section className='sec-pedido'>
            <h5>DETALLE DEL PEDIDO</h5>
        </section>
    );
}