import React, { Component } from 'react';
import './footer.css'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( 
            <footer className='footer'>
                <p class="card-text pFooter">@2021 Todos los derechos reservados</p>
            </footer>
         );
    }
}
 
export default Footer;