import React, { useState, useEffect } from "react";
import "./categorias.css";
import CardIni from "../Inicio/card";
import axios from "axios";
import DirectionsCarOutlinedIcon from "@material-ui/icons/DirectionsCarOutlined";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import BuildIcon from "@material-ui/icons/Build";
import MicNoneIcon from "@material-ui/icons/MicNone";
import SportsBasketballIcon from "@material-ui/icons/SportsBasketball";
import ComputerIcon from "@material-ui/icons/Computer";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import TwoWheelerIcon from "@material-ui/icons/TwoWheeler";
import KitchenIcon from "@material-ui/icons/Kitchen";
import EmojiNatureIcon from "@material-ui/icons/EmojiNature";
import ExtensionIcon from "@material-ui/icons/Extension";
import { Col, Row } from "react-bootstrap";
import PrimerFiltro from "./primerFiltro";
import { Chip, CircularProgress } from "@material-ui/core";

export default function Categoria(props) {
  const [publicaciones, setPublicaciones] = useState(null);
  const [rendered, setRendered] = useState(false);

  const [filtro, setFiltro] = useState(false);

  const [filtros, setFiltros] = useState([]);

  const [icono, setIcono] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const getPublicFilter = () => {
    let url = "https://idecommerce.cl/services/api/get/publicaciones/filter";
    axios
      .post(
        url,
        {
          categoria:
            props.match.params.cate === "Autos"
              ? "Autos, Motos y Otros"
              : props.match.params.cate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        let lsPubscat = Object.keys(response.data).map((key) => {
          setFiltros(response.data[key].filtro);
          return response.data[key];
        });

        setPublicaciones(lsPubscat);
        setRendered(true);
      });
  };

  useEffect(() => {
    if (!rendered) {
      switch (props.match.params.cate) {
        case "Autos":
          setIcono(<DirectionsCarOutlinedIcon className="icono" />);
          break;

        case "Celulares y Telefonìa":
          setIcono(<PhoneIphoneIcon className="icono" />);
          break;

        case "Inmuebles":
          setIcono(<HomeWorkIcon className="icono" />);
          break;

        case "Herramientas":
          setIcono(<BuildIcon className="icono" />);
          break;

        case "Electrónica, Audio y Video":
          setIcono(<MicNoneIcon className="icono" />);
          break;

        case "Deporte y fitness":
          setIcono(<SportsBasketballIcon className="icono" />);
          break;

        case "Computacion":
          setIcono(<ComputerIcon className="icono" />);
          break;

        case "Consolas y Videojuegos":
          setIcono(<SportsEsportsIcon className="icono" />);
          break;
        case "Accesorios para vehìculos":
          setIcono(<TwoWheelerIcon className="icono" />);
          break;

        case "Electrodomèstico":
          setIcono(<KitchenIcon className="icono" />);
          break;

        case "Belleza y cuidado personal":
          setIcono(<EmojiNatureIcon className="icono" />);
          break;

        case "Juegos y juguetes":
          setIcono(<ExtensionIcon className="icono" />);
          break;

        default:
          break;
      }
      getPublicFilter();
    }
  }, [publicaciones]);

  const handleDelete = () => {};

  const handleFiltro = (id, name) => {
    setShowSpinner(true);
    setFiltro(!filtro);
    setSelectedFilter(name);
    setSelectedId(id);
    setTimeout(() => {
      setShowSpinner(false);
    }, 1500);
  };

  return (
    <section className="box-categoria">
      <div className="box-categoria-info  mt-2">
        <div className="">
          <div className="border  p-3  box-categoria-tipo">
            {icono}
            <p>{props.match.params.cate}</p>
          </div>

          {showSpinner && (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "50vh" }}
            >
              <CircularProgress size={60} />
            </div>
          )}

          {!showSpinner && filtro && (
            <PrimerFiltro
              handleFiltro={handleFiltro}
              categoria={
                props.match.params.cate === "Autos"
                  ? "Autos, Motos y Otros"
                  : props.match.params.cate
              }
              selectedId={selectedId}
              setPublicaciones={setPublicaciones}
              getPublicFilter={getPublicFilter}
              setRendered={setRendered}
              setShowSpinner={setShowSpinner}
              selectedFilter={selectedFilter}
            />
          )}

          {!showSpinner && !filtro && (
            <div>
              {filtros &&
                filtros.map((filt) => {
                  return (
                    <Chip
                      size="small"
                      label={filt.name}
                      className="chip-cat-uno"
                      onClick={(e) => {
                        handleFiltro(filt.id, filt.name);
                      }}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
      <div className="box-categoria-productos  ">

      {showSpinner && (
            <div
              className="d-flex justify-content-center align-items-center w-100"
              style={{ height: "50vh" }}
            >
              <CircularProgress size={60} />
            </div>
          )}

        {!showSpinner && rendered &&
          publicaciones.length > 0 &&
          publicaciones.map((item) => {
            return (
              <>
                <CardIni
                  id={item.id}
                  Titulo={item.Titulo}
                  Precio={item.Precio}
                  created_at={item.created_at}
                  publicacion={item}
                />
              </>
            );
          })}
      </div>
    </section>
  );
}
