import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Login from "./login";
import "./login.css";
import Registro from "./registro";
import { Link } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import axios from "axios";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import Snackbar from '@material-ui/core/Snackbar';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LoginSection() {
  const [value, setValue] = React.useState(0);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [bgToast, setBgToast] = useState('');
  const [title, setTitle] = useState('');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [forgotPassword, setforgotPassword] = useState(false);
  const [email, setEmail] = useState("");

  const submitEmailUser = async (e) => {
    e.preventDefault();
    if (email === "") return alert("Ingrese un email");
    let url = "https://idecommerce.cl/services/api/forgot-password";
    await axios
      .post(
        url,
        { email: email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {setShowToast(true);setMessage(result.data.status);setBgToast('Success');setTitle('Exito')})
      .catch((error) => {setShowToast(true);setMessage('Error  el usuario no se encontro');setBgToast('Danger');setTitle('Error')});
  };

    const submitOlvidar = e => {
      e.preventDefault();
      setforgotPassword(false);
    }

  return (
    <>
       {forgotPassword && (
        <div className='div-contraseña position-relative'>
          {showToast  && 
          <ToastContainer className="p-3" position={'top-center'}>

                    <Toast bg={bgToast.toLowerCase()} onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
                    <Toast.Header closeButton={false}>
                      <strong className="me-auto">{title}</strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                  </Toast>
                  </ToastContainer>
            }
           <Link  className="position-absolute  btn-atras">
             <button className='btn ' onClick={submitOlvidar}>
                <KeyboardBackspaceIcon fontSize="large" />
             </button>
          </Link>
        <div className='w-50 me-auto ms-auto div-contraseña-text'>
          <h4 className='h4-contraseña'>OLVIDÉ MI CLAVE ¿CÓMO RECUPERAR LA CONTRASEÑA?</h4>
          <p className='p-contraseña'>El cambio de Contraseña se realizara con el email ingresado.</p>
          <p className='p-contraseña'>Se mandara un email a la cuenta seleccionada verificando que la cuenta le pertenece, podra recuperar la contraseña a partir del link proporcionado en el email.</p>
            
            <Form.Group className="mb-3 mt-5">
              <Form.Label>Escribe en el campo el email a cambiar la Contraseña.</Form.Label>
              <Form.Control type="text"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Ingrese el email" />
            </Form.Group>
            <Button onClick={submitEmailUser} variant="primary" type="submit">
                Enviar
            </Button>
    
          </div>
        </div>
      )}
    {!forgotPassword && (
        <div className=" login w-50 me-auto ms-auto">
          <Link to="/" className="position-absolute  btn-atras">
            <KeyboardBackspaceIcon fontSize="large" />
          </Link>
        <>
          <AppBar className="appbar-box" className="mt-3" position="static">
            <Tabs
              className="tabs-box"
              value={value}
              variant="fullWidth"
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab className="tab-box" label="Ingresar" {...a11yProps(0)} />
              <Tab className="tab-box" label="Registrarse" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Login
              changetoReset={(e) => {
                e.preventDefault();
                setforgotPassword(true);
              }}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Registro />
          </TabPanel>
        </>

    </div> )}
 </>);
}
