import axios from "axios";
import React, { useEffect, useState } from "react";
import "./login.css";
import { Button, Form, Toast, ToastContainer } from "react-bootstrap";
import { useHistory } from "react-router";

export default function ForgotPassword(props) {
  const [state, setState] = useState({
    token: "",
    email: "",
    password: "",
    password_confirmation: "",
  });

  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState("");
  const [bgToast, setBgToast] = useState("");
  const [title, setTitle] = useState("");
  const  history =  useHistory();

  const submitNewPassword = async (e) => {
    e.preventDefault();
    let url = "https://idecommerce.cl/services/api/reset-password";
    await axios
      .post(
        url,
        {
          email: state.email,
          password: state.password,
          password_confirmation: state.password_confirmation,
          token: props.match.params.token,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setShowToast(true);
        setMessage(result.data.result);
        setBgToast("Success");
        setTitle("Exito");
        setTimeout(() => {
          history.push('/login');
        }, 4500);
      })
      .catch((error) => {
        setShowToast(true);
        setMessage("Error  el usuario no se encontro");
        setBgToast("Danger");
        setTitle("Error");
      });
  };

  const handleValueField = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <section className="div-contraseña" style={{ height: "100vh" }}>
      {showToast && (
        <ToastContainer className="p-3" position={"top-center"}>
          <Toast
            bg={bgToast.toLowerCase()}
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Header closeButton={false}>
              <strong className="me-auto">{title}</strong>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <div className="w-50 me-auto ms-auto div-contraseña-text ">
        <Form.Group className="mb-3 mt-3 h-auto">
          <h4 className="h4-contraseña">Recuperar la contraseña</h4>
          <Form.Label className="mt-3">Escribe en el campo el Email</Form.Label>
          <Form.Control
            type="text"
            className="d-block  ms-auto me-auto"
            placeholder="Ingrese el Email"
            name="email"
            onChange={handleValueField}
          />

          <Form.Label className="mt-3">
            Escribe en el campo la nueva Contraseña
          </Form.Label>
          <Form.Control
            type="password"
            className="d-block ms-auto me-auto"
            placeholder="Nueva contraseña"
            name="password"
            onChange={handleValueField}
          />

          <Form.Label className="mt-3">
            Escribe en el campo la repetición de la nueva Contraseña
          </Form.Label>
          <Form.Control
            type="password"
            className="d-block ms-auto me-auto "
            placeholder="Repetir contraseña"
            name="password_confirmation"
            onChange={handleValueField}
          />

          <Button className="btn  btn-primary mt-4" onClick={submitNewPassword}>
            Restablecer la contraseña
          </Button>
        </Form.Group>
      </div>
    </section>
  );
}
