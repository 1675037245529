import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "./publicacion.css";
import { PhotoCamera } from "@material-ui/icons";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import axios from "axios";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";

/**
 *
 * @var {titulo} Titulo de la publicacion
 * @var {tipo} Tipo de publicacion [Auto,Moto]
 * @var {info} Informacion de la publicacion
 * @returns
 *
 */

export default function Publicacion(props) {
  let history = useHistory();

  const [cookies, setCookie, removeCookie] = useCookies(["auth_user"]);

  const [imgpre, setimgpre] = useState(null);
  const [file_data, setFiledata] = useState(null);

  const [imgsec, setimgsec] = useState(null);
  const [file_data_second, setFiledatasecond] = useState(null);

  const [imgthr, setimgthr] = useState(null);
  const [file_data_three, setFiledatathree] = useState(null);

  const [imgfour, setimgfour] = useState(null);
  const [file_data_four, setFiledatafour] = useState(null);

  const [imgfive, setimgfive] = useState(null);
  const [file_data_five, setFiledatafive] = useState(null);

  const [imgsix, setimgsix] = useState(null);
  const [file_data_six, setFiledatasix] = useState(null);

  const [titulo, setTitulo] = useState("");
  const [tipo, setTipo] = useState("");

  const [tipoAuto, setTipoAuto] = useState("");
  const [tipoAutoBrand, setTipoAutoBrand] = useState("");
  const [tipoAutoType, setTipoAutoType] = useState("");

  const [filterFather, setFilterFather] = useState("");
  const [filterByBrand, setFilterByBrand] = useState("");
  const [filterByType, setFilterByType] = useState("");


  const [info, setInfo] = useState("");
  const [precio, setPrecio] = useState("");

  const [cara, setCara] = useState(null);
  const [val, setVal] = useState(null);

  const [cara_dos, setCarados] = useState(null);
  const [val_dos, setValdos] = useState(null);

  const [cara_tres, setCaratres] = useState(null);
  const [val_tres, setValtres] = useState(null);

  const [cara_cuatro, setCaracuatro] = useState(null);
  const [val_cuatro, setValcuatro] = useState(null);

  const [cara_cinco, setCaracinco] = useState(null);
  const [val_cinco, setValcinco] = useState(null);

  const [cara_seis, setCaraseis] = useState(null);
  const [val_seis, setValseis] = useState(null);

  const preloadImg = (e) => {
    setimgpre(URL.createObjectURL(e.target.files[0]));
    setFiledata(e.target.files[0]);
  };

  const preloadImgsec = (e) => {
    setimgsec(URL.createObjectURL(e.target.files[0]));
    setFiledatasecond(e.target.files[0]);
  };

  const preloadImgthr = (e) => {
    setimgthr(URL.createObjectURL(e.target.files[0]));
    setFiledatathree(e.target.files[0]);
  };

  const preloadImgfour = (e) => {
    setimgfour(URL.createObjectURL(e.target.files[0]));
    setFiledatafour(e.target.files[0]);
  };

  const preloadImgfive = (e) => {
    setimgfive(URL.createObjectURL(e.target.files[0]));
    setFiledatafive(e.target.files[0]);
  };

  const preloadImgsix = (e) => {
    setimgsix(URL.createObjectURL(e.target.files[0]));
    setFiledatasix(e.target.files[0]);
  };

  const uploadImgs = (idpublic, file) => {
    let endpoint =
      "https://idecommerce.cl/services/api/create/publicacion/multimedia";
    var bodyImgs = new FormData();
    bodyImgs.append("file", file);
    bodyImgs.append("idPublic", idpublic);

    const config = {
      headers: {
        Authorization: "Bearer " + cookies.auth_user,
      },
    };
    axios.post(endpoint, bodyImgs, config);
  };

  const createPublic = async () => {
    let endpoint = "https://idecommerce.cl/services/api/create/publicacion";

    let lscaras = [];
    if (cara && val) lscaras.push({ name: cara, valor: val });
    if (cara_dos && val_dos) lscaras.push({ name: cara_dos, valor: val_dos });
    if (cara_tres && val_tres)
      lscaras.push({ name: cara_tres, valor: val_tres });
    if (cara_cuatro && val_cuatro)
      lscaras.push({ name: cara_cuatro, valor: val_cuatro });
    if (cara_cinco && val_cinco)
      lscaras.push({ name: cara_cinco, valor: val_cinco });
    if (cara_seis && val_seis)
      lscaras.push({ name: cara_seis, valor: val_seis });

    let lsFiles = [];
    if (file_data) lsFiles.push({ file: file_data });
    if (file_data_second) lsFiles.push({ file: file_data_second });
    if (file_data_three) lsFiles.push({ file: file_data_three });
    if (file_data_four) lsFiles.push({ file: file_data_four });
    if (file_data_five) lsFiles.push({ file: file_data_five });
    if (file_data_six) lsFiles.push({ file: file_data_six });

    const params = {
      Titulo: titulo,
      Informacion: info,
      Precio: precio,
      Tipo: tipo,
      Caraceristicas: lscaras,
      filterFa:tipoAuto.id,
      filterBrand:tipoAutoBrand.id,
      filterType:tipoAutoType.id,
    };
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies.auth_user,
      },
    };
    await axios.post(endpoint, params, config).then((res) => {
      if (lsFiles.length > 0) {
        try {
          lsFiles.forEach((element) => {
            uploadImgs(res.data.idPublic, element.file);
          });
          setTimeout(() => {
            history.push("/Publicacion/" + res.data.idPublic);
          }, 2000);
        } catch (error) {
          alert("Error con la base de datos");
        }
      }
    });
  };

  useEffect(() => {
    if (!cookies.auth_user) {
      history.push("/login");
    }
  });

  useEffect(() => {
    if (tipo == "Autos, Motos y Otros") {
      let endpoint = "https://idecommerce.cl/services/api/get/filters";
      axios
        .post(endpoint, { filterType: "Autos, Motos y Otros" }, {})
        .then((resp) => {
          setFilterFather(resp.data);
        });
    }
  }, [cookies.auth_user, tipo]);


  useEffect(() => {
    if (tipoAuto) {
      let endpoint = "https://idecommerce.cl/services/api/get/filters/by/brands-types";

      axios
        .post(endpoint, { filterid: tipoAuto.id}, {})
        .then((resp) => {
          console.log(resp);
          setFilterByBrand(resp.data.filtromarca);
          setFilterByType(resp.data.filtrotype);
        });
    }else if(tipo !== "Autos, Motos y Otros"){
      setFilterByBrand(null);
      setFilterByType(null);
    }
  }, [cookies.auth_user, tipo, tipoAuto]);

  useEffect(() => {
    if(tipo !== "Autos, Motos y Otros"){
      setFilterByBrand(null);
      setFilterByType(null);
    }
  }, [filterByBrand,filterByType, tipo])

  return (
    <section className="section-box pb-5 ">
      <form noValidate autoComplete="off" className="">
        <section className="d-flex">
          <Paper
            variant="outlined"
            className="box-img d-flex align-items-center justify-content-center"
            square
          >
            {imgpre && (
              <img
                src={imgpre}
                className="w-100 h-auto"
                style={{ maxHeight: "100%" }}
              />
            )}
            {!imgpre && (
              <>
                <input
                  accept="image/*"
                  className={"d-none"}
                  id="icon-button-file"
                  type="file"
                  name="imgpre"
                  onChange={preloadImg}
                />
                <label htmlFor="icon-button-file">
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <PhotoCamera className="icono-photo" />
                  </IconButton>
                </label>
              </>
            )}
          </Paper>
          <section className="section-box-info ps-3    text-start">
            <section className="d-flex">
              <Paper
                variant="outlined"
                className="box-subimg d-flex align-items-center justify-content-center"
                square
              >
                {imgsec && (
                  <img
                    src={imgsec}
                    className="w-100 h-auto"
                    style={{ maxHeight: "100%" }}
                  />
                )}
                {!imgsec && (
                  <>
                    <input
                      accept="image/*"
                      className={"d-none"}
                      id="icon-button-file"
                      type="file"
                      name="imgsec"
                      onChange={preloadImgsec}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera className="icono-photo" />
                      </IconButton>
                    </label>
                  </>
                )}
              </Paper>

              <Paper
                variant="outlined"
                className="box-subimg d-flex align-items-center justify-content-center"
                square
              >
                {imgthr && (
                  <img
                    src={imgthr}
                    className="w-100 h-auto"
                    style={{ maxHeight: "100%" }}
                  />
                )}
                {!imgthr && (
                  <>
                    <input
                      accept="image/*"
                      className={"d-none"}
                      id="icon-button-file"
                      type="file"
                      name="imgthr"
                      onChange={preloadImgthr}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera className="icono-photo" />
                      </IconButton>
                    </label>
                  </>
                )}
              </Paper>

              <Paper
                variant="outlined"
                className="box-subimg d-flex align-items-center justify-content-center"
                square
              >
                {imgfour && (
                  <img
                    src={imgfour}
                    className="w-100 h-auto"
                    style={{ maxHeight: "100%" }}
                  />
                )}
                {!imgfour && (
                  <>
                    <input
                      accept="image/*"
                      className={"d-none"}
                      id="icon-button-file"
                      type="file"
                      onChange={preloadImgfour}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera className="icono-photo" />
                      </IconButton>
                    </label>
                  </>
                )}
              </Paper>

              <Paper
                variant="outlined"
                className="box-subimg d-flex align-items-center justify-content-center"
                square
              >
                {imgfive && (
                  <img
                    src={imgfive}
                    className="w-100 h-auto"
                    style={{ maxHeight: "100%" }}
                  />
                )}
                {!imgfive && (
                  <>
                    <input
                      accept="image/*"
                      className={"d-none"}
                      id="icon-button-file"
                      type="file"
                      onChange={preloadImgfive}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera className="icono-photo" />
                      </IconButton>
                    </label>
                  </>
                )}
              </Paper>

              <Paper
                variant="outlined"
                className="box-subimg d-flex align-items-center justify-content-center"
                square
              >
                {imgsix && (
                  <img
                    src={imgsix}
                    className="w-100 h-auto"
                    style={{ maxHeight: "100%" }}
                  />
                )}
                {!imgsix && (
                  <>
                    <input
                      accept="image/*"
                      className={"d-none"}
                      id="icon-button-file"
                      type="file"
                      name="imgsix"
                      onChange={preloadImgsix}
                    />
                    <label htmlFor="icon-button-file">
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                      >
                        <PhotoCamera className="icono-photo" />
                      </IconButton>
                    </label>
                  </>
                )}
              </Paper>
            </section>

            <TextField
              className=" text-caracteristica mt-3"
              label="Titulo de la publicacion"
              onChange={(e) => {
                setTitulo(e.target.value);
              }}
            />

            <FormControl className="form-producto d-block ">
              <InputLabel
                id="demo-simple-select-label"
                className="text-caracteristica "
              >
                Tipo de producto
              </InputLabel>
              <Select
                className="text-caracteristica"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={(e) => {
                  if(e.target.value != "Autos, Motos y Otros"){
                    setFilterByBrand(null);
                    setFilterByType(null);
                    setFilterFather(null);
                  }
                  setTipo(e.target.value);
                }}
              >
                <MenuItem
                  value={"Autos, Motos y Otros"}
                  className="text-caracteristica"
                >
                  Autos, Motos y Otros
                </MenuItem>
                <MenuItem
                  value={"Celulares y Telefonìa"}
                  className="text-caracteristica"
                >
                  Celulares y Telefonìa
                </MenuItem>
                <MenuItem value={"Inmuebles"} className="text-caracteristica">
                  Inmuebles
                </MenuItem>
                <MenuItem
                  value={"Herramientas"}
                  className="text-caracteristica"
                >
                  Herramientas
                </MenuItem>
                <MenuItem
                  value={"Electrónica, Audio y Video"}
                  className="text-caracteristica"
                >
                  Electrónica, Audio y Video
                </MenuItem>
                <MenuItem
                  value={"Deporte y fitness"}
                  className="text-caracteristica"
                >
                  Deporte y fitness
                </MenuItem>
                <MenuItem value={"Computación"} className="text-caracteristica">
                  Computación
                </MenuItem>
                <MenuItem
                  value={"Consolas y Videojuegos"}
                  className="text-caracteristica"
                >
                  Consolas y Videojuegos
                </MenuItem>
                <MenuItem
                  value={"Accesorios para vehìculos"}
                  className="text-caracteristica"
                >
                  Accesorios para vehìculos
                </MenuItem>
                <MenuItem
                  value={"Electrodomèsticos"}
                  className="text-caracteristica"
                >
                  Electrodomèsticos
                </MenuItem>
                <MenuItem
                  value={"Belleza y cuidado personal"}
                  className="text-caracteristica"
                >
                  Belleza y cuidado personal
                </MenuItem>
                <MenuItem
                  value={"Juegos y juguetes"}
                  className="text-caracteristica"
                >
                  Juegos y juguetes
                </MenuItem>
              </Select>
            </FormControl>

            {filterFather && (
              <FormControl className="form-producto d-block ">
                <InputLabel
                  id="demo-simple-select-label"
                  className="text-caracteristica "
                >
                  Tipo de veichulo
                </InputLabel>
                <Select
                  className="text-caracteristica"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => {
                    if(tipo != "Autos, Motos y Otros"){
                      setFilterByBrand(null);
                      setFilterByType(null);
                      setFilterFather(null);
                    }
                    setTipoAuto(e.target.value);
                  }}
                >
                  {filterFather.map((filt) => {
                    return (
                      <MenuItem
                        value={filt}
                        className="text-caracteristica"
                      >
                       {filt.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}

          {filterByBrand && (
              <FormControl className="form-producto d-block ">
                <InputLabel
                  id="demo-simple-select-label"
                  className="text-caracteristica "
                >
                  Marca de veichulo
                </InputLabel>
                <Select
                  className="text-caracteristica"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => {
                    setTipoAutoBrand(e.target.value);
                  }}
                >
                  {filterByBrand.map((filt) => {
                    return (
                      <MenuItem
                        value={filt}
                        className="text-caracteristica"
                      >
                       {filt.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )} 
            {filterByType && (
              <FormControl className="form-producto d-block ">
                <InputLabel
                  id="demo-simple-select-label"
                  className="text-caracteristica "
                >
                  Tipo de veichulo
                </InputLabel>
                <Select
                  className="text-caracteristica"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  onChange={(e) => {
                    setTipoAutoType(e.target.value);
                  }}
                >
                  {filterByType.map((filt) => {
                    return (
                      <MenuItem
                        value={filt}
                        className="text-caracteristica"
                      >
                       {filt.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}  

            <div className="mt-3 mb-3">
              <textarea
                className="w-100 p-2"
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Información de la publicación"
                onChange={(e) => {
                  setInfo(e.target.value);
                }}
              ></textarea>
            </div>
          </section>
        </section>

        <h3 className="text-start section-box-caracteristics ">
          Características{" "}
        </h3>
        <section className="section-box-caracteristics d-flex align-items-center ">
          <section className="w-50 caracteristicas">
            <div className="d-flex align-items-center ">
              <TextField
                label="Característica"
                className="me-3 "
                onChange={(e) => {
                  setCara(e.target.value);
                }}
              />

              <ArrowRightAltIcon className="mt-3" />
              <TextField
                label="Valor"
                className="ms-3"
                onChange={(e) => {
                  setVal(e.target.value);
                }}
              />
            </div>

            <div className="d-flex align-items-center">
              <TextField
                label="Característica"
                className="me-3"
                onChange={(e) => {
                  setCarados(e.target.value);
                }}
              />
              <ArrowRightAltIcon className="mt-3" />
              <TextField
                label="Valor"
                className="ms-3"
                onChange={(e) => {
                  setValdos(e.target.value);
                }}
              />
            </div>
            <div className="d-flex align-items-center">
              <TextField
                label="Característica"
                className="me-3"
                onChange={(e) => {
                  setCaratres(e.target.value);
                }}
              />
              <ArrowRightAltIcon className="mt-3" />
              <TextField
                label="Valor"
                className="ms-3"
                onChange={(e) => {
                  setValtres(e.target.value);
                }}
              />
            </div>
          </section>
          <section>
            <div className="d-flex align-items-center">
              <TextField
                label="Característica"
                className="me-3"
                onChange={(e) => {
                  setCaracuatro(e.target.value);
                }}
              />
              <ArrowRightAltIcon className="mt-3" />
              <TextField
                label="Valor"
                className="ms-3"
                onChange={(e) => {
                  setValcuatro(e.target.value);
                }}
              />
            </div>

            <div className="d-flex align-items-center">
              <TextField
                label="Característica"
                className="me-3"
                onChange={(e) => {
                  setCaracinco(e.target.value);
                }}
              />
              <ArrowRightAltIcon className="mt-3" />
              <TextField
                label="Valor"
                className="ms-3"
                onChange={(e) => {
                  setValcinco(e.target.value);
                }}
              />
            </div>
            <div className="d-flex align-items-center">
              <TextField
                label="Característica"
                className="me-3"
                onChange={(e) => {
                  setCaraseis(e.target.value);
                }}
              />
              <ArrowRightAltIcon className="mt-3" />
              <TextField
                label="Valor"
                className="ms-3"
                onChange={(e) => {
                  setValseis(e.target.value);
                }}
              />
            </div>
          </section>
        </section>

        <section className="section-box-vendor text-start  d-flex  align-items-center justify-content-between   mt-4 ">
          <div calssName="w-75">
            <InputLabel
              htmlFor="filled-adornment-amount"
              className="mt-3 mb-3 text-caracteristica"
            >
              Precio del producto
            </InputLabel>
            <OutlinedInput
              id="filled-adornment-amount"
              value={precio}
              onChange={(e) => {
                setPrecio(e.target.value);
              }}
              className="ms-auto"
              startAdornment={
                <InputAdornment position="start" className="">
                  $
                </InputAdornment>
              }
            />
          </div>

          <div>
            <Button
              variant="contained"
              color="link"
              size="small"
              className={"d-block ms-auto  mt-5 text-caracteristica  w-100"}
              onClick={createPublic}
            >
              Crear Publicación
            </Button>
          </div>
        </section>
      </form>
    </section>
  );
}
