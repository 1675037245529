import React, { createContext, useState } from "react";

import "./login.css";
import { Button, Spinner, Toast, ToastContainer } from "react-bootstrap";
import { Grid, TextField } from "@material-ui/core";

import { useCookies } from "react-cookie";
import axios from "axios";
import { useHistory } from "react-router";
import logo from "./mano.png";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { Link } from "react-router-dom";

export default function Login(props) {
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(["email"]);
  const [cookiesAvatar, setCookieAvatar, removeCookieAvatar] = useCookies([
    "avatar",
  ]);

  let history = useHistory();

  const loginUser = () => {
    setLoading(true);
    let url = "https://idecommerce.cl/services/api/login";
    axios
      .post(
        url,
        {
          email: Email,
          password: Password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setCookieAvatar("avatar", response.data.user.logo);
        setCookie("email", response.data.user.email);
        setCookie("auth_user", response.data.token);
        history.push("/");
      })
      .catch((error) => {
        setShowToast(true);
        setLoading(false);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      });
  };

  return (
    <section className="secPrin  position-relative">
      {showToast && (
        <ToastContainer className="p-3" position={"top-center"}>
          <Toast
            bg={"danger"}
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={7000}
            autohide
          >
            <Toast.Body className="text-center text-white">
              El usuario/contraseña son incorrectos
            </Toast.Body>
          </Toast>
        </ToastContainer>
      )}
      <section className="w-100">
        <img
          src={logo}
          className="w-25 ms-auto d-block me-auto rounded-circle mt-4"
          alt=""
        />

        <form
          className="w-75 ms-auto me-auto text-center
        "
          noValidate
          autoComplete="off"
        >
          <Grid container alignItems="flex-end" className="w-100">
            <Grid item xs={1}>
              <AccountBoxIcon />
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="standard-basic"
                className="iconoUser"
                label="Correo electronico"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end" className="w-100 mt-3">
            <Grid item xs={1}>
              <VpnKeyIcon />
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="standard-password-input"
                label="Contraseña"
                type="password"
                className="w-100"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <div className="mt-4 mb-4 w-100">
            <Button
              className="loginBotom"
              disabled={isLoading}
              onClick={loginUser}
            >
              {isLoading ? "Cargando.... " : "INGRESAR"}
              {isLoading && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </Button>
          </div>

          <button onClick={props.changetoReset} className="btn ">
            <a href="">¿Olvidaste tu contraseña?</a>
          </button>
        </form>
      </section>
    </section>
  );
}
