import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import "./login.css";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useCookies } from "react-cookie";
import pdfPol from "../pdf/Politica de privacidad idecommerce.pdf";
import pdfCon from "../pdf/Condiciones generales idecommerce.pdf";
import { useHistory } from "react-router";

export default function Registro() {
  const [cookies, setCookie, removeCookie] = useCookies(["email"]);
  const [cookiesAvatar, setCookieAvatar, removeCookieAvatar] = useCookies([
    "avatar",
  ]);

  let history = useHistory();

  const [state, setState] = useState({
    correo: "",
    nombre: "",
    contra: "",
    contrarep: "",
    direccion: "",
    pais:"",
    ciudad:"",
    nacimiento: "",
    telefono: "",
    poli: false,
    condi: false,
    error_correo: false,
    error_nombre: false,
    error_contra: false,
    error_contrarep: false,
    error_direccion: false,
    error_telefono: false,
  });

  const validField = (e) => {
    e.preventDefault();
    if (state.correo == "") {
      setState((prevState) => ({ ...prevState, ["error_correo"]: true }));
      return;
    }
    if (state.nombre == "") {
      setState((prevState) => ({ ...prevState, ["error_nombre"]: true }));
      return;
    }
    if (state.contra == "") {
      setState((prevState) => ({ ...prevState, ["error_contra"]: true }));
      return;
    }
    if (state.contrarep == "") {
      setState((prevState) => ({ ...prevState, ["error_contrarep"]: true }));

      return;
    }
    if (state.direccion == "") {
      setState((prevState) => ({ ...prevState, ["error_direccion"]: true }));
      return;
    }
    if (state.nacimiento == "") {
      setState((prevState) => ({ ...prevState, ["error_nacimiento"]: true }));

      return;
    }
    if (state.telefono == "") {
      setState((prevState) => ({ ...prevState, ["error_telefono"]: true }));
      return;
    }

    let polycond = (state.poli  && state.condi)  ? "true" : false;
    if (!polycond) {
      alert("Complete  las condiciones");
      return;
    }

    let url = "https://idecommerce.cl/services/api/createUser";
    const data = {
      email: state.correo,
      name: state.nombre,
      password: state.contra,
      logo: "",
      telefono: state.telefono,
      direccion: state.direccion,
      ciudad: state.ciudad,
      pais: state.pais,
      nacimiento: state.nacimiento,
      politicasycondiciones: polycond,
    };
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setCookieAvatar("avatar", response.data.user.logo);
        setCookie("email", response.data.user.email);
        setCookie("auth_user", response.data.token);
        history.push("/");
      });
  };

  const handleValueField = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    if (e.target.value != "")
      setState((prevState) => ({
        ...prevState,
        ["error_" + e.target.name]: false,
      }));
  };

  const handleCheCond = (e) => {
    let boolean = !state.condi;
    setState((prevPub) => ({ ...prevPub, [e.target.name]: boolean }));
  };
  const handleChePoli = (e) => {
    let boolean = !state.poli;
    setState((prevPub) => ({ ...prevPub, [e.target.name]: boolean }));
  };
  return (
    <form autoComplete="off" className="pe-3  ps-3">
      <TextField
        className="iconoUser"
        label="Correo electronico"
        required
        error={state.error_correo}
        name="correo"
        onChange={handleValueField}
      />
      <TextField
        label="Nombre"
        error={state.error_nombre}
        onChange={handleValueField}
        name="nombre"
        required
        className="iconoUser mt-3"
      />
      <TextField
        label="Contraseña"
        type="password"
        name="contra"
        error={state.error_contra}
        onChange={handleValueField}
        required
        className="iconoUser mt-3"
      />
      <TextField
        label="Repetir  contraseña"
        type="password"
        error={state.error_contrarep}
        required
        onChange={handleValueField}
        name="contrarep"
        className="iconoUser mt-3"
      />
      <TextField
        label="Direccion"
        error={state.error_direccion}
        required
        onChange={handleValueField}
        name="direccion"
        className="iconoUser mt-3"
      />
        <TextField
        label="Ciudad"
        error={state.error_direccion}
        required
        onChange={handleValueField}
        name="ciudad"
        className="iconoUser mt-3"
      />
      <TextField
        label="Pais"
        error={state.error_direccion}
        required
        onChange={handleValueField}
        name="pais"
        className="iconoUser mt-3"
      />
      <TextField
        label="Nacimiento"
        error={state.error_nacimiento}
        required
        onChange={handleValueField}
        name="nacimiento"
        className="iconoUser mt-3"
      />
      <TextField
        label="Telefono"
        error={state.error_telefono}
        name="telefono"
        onChange={handleValueField}
        required
        className="iconoUser mt-3"
      />
      <section className="sec-check">
        <div class="form-check me-5">
          <input
            class="form-check-input"
            required
            name="poli"
            checked={state.poli}
            type="checkbox"
            onChange={handleChePoli}
            id="flexCheckDefault"
          />
          <a
            className="a-pdf"
            download="Politica de privacidad idecommerce.pdf"
            href={pdfPol}
          >
            Políticas de privacidad
          </a>
        </div>

        <div class="form-check">
          <input
            class="form-check-input"
            required
            name="condi"
            type="checkbox"
            checked={state.condi}
            onChange={handleCheCond}
          />
          <a
            className="a-pdf"
            download="Condiciones generales idecommerce.pdf"
            href={pdfCon}
          >
            Condiciones generales
          </a>
        </div>
      </section>
      <Button
        className="registroBotom mt-4 ms-auto  me-auto  d-block"
        onClick={validField}
      >
        Registrarse
      </Button>
    </form>
  );
}
