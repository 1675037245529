import logo from "./logo.svg";
import "./App.css";
import Inicio from "./Componentes/Inicio/inicio";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./Componentes/Login/login";
import MisAvisos from "./Componentes/Usuario/MisAvisos/misAvisos";
import Publicar from "./Componentes/Usuario/Publicar/publicar";
import Footer from "./Componentes/Footer/footer";
import Categoria from "./Componentes/Categorias/categorias";
import LoginSection from "./Componentes/Login/loginSection";
import Publicacion from "./Componentes/Publicacion/publicacion";
import Perfil from "./Componentes/Usuario/Perfil/perfil";
import PublicacionEdit from "./Componentes/Publicacionedit/publicacionedit";
import Politicas from "./Componentes/Politicas/politicas";
import Soporte from "./Componentes/Soporte/soporte";
import Carrito from "./Componentes/Carrito/carrito";
import ForgotPassword from "./Componentes/Login/forgotPassword";
import MenuUno from "./Componentes/Menu/menuUno";

const mainsection = ({ match }) => {
  return (
    <>
      <Route component={MenuUno}></Route>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Inicio}></Route>
          <Route exact path="/MisAvisos" component={MisAvisos}></Route>
          <Route exact path="/Publicar" component={Publicacion}></Route>
          <Route
            exact
            path="/Publicacion/:idPublic"
            component={Publicar}
          ></Route>
          <Route
            exact
            path="/Publicacion/:idPublic/edit"
            component={PublicacionEdit}
          ></Route>
          <Route exact path="/Categoria/:cate" component={Categoria}></Route>
          <Route exact path="/User/Perfil" component={Perfil}></Route>
          <Route exact path="/Politicas" component={Politicas}></Route>
          <Route exact path="/Soporte" component={Soporte}></Route>
          <Route exact path="/Carrito" component={Carrito}></Route>
        </Switch>
      </div>
      <Footer />
    </>
  );
};

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={LoginSection}></Route>
        <Route
            exact
            path="/reset-password/token=:token"
            component={ForgotPassword}
          ></Route>
        <Route path="/" component={mainsection}></Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
